import { DEVICES_COLUMN_NAME } from 'models'

export const defaultVisibleColumns = [
  DEVICES_COLUMN_NAME.ONYXIA_PRODUCT_INSTANCE,
  DEVICES_COLUMN_NAME.HOSTNAME,
  DEVICES_COLUMN_NAME.SERIAL_NUMBER,
  DEVICES_COLUMN_NAME.ASSET_TYPE,
  DEVICES_COLUMN_NAME.GROUP_NAME,
  DEVICES_COLUMN_NAME.FIRST_SEEN,
  DEVICES_COLUMN_NAME.LAST_SEEN,
  DEVICES_COLUMN_NAME.MAC_ADDRESSES,
  DEVICES_COLUMN_NAME.MAC_ADDRESSES,
  DEVICES_COLUMN_NAME.IP_ADDRESSES,
  DEVICES_COLUMN_NAME.OS_NAME
]

export const cpiSpecificDefaults = {
  cpi091: DEVICES_COLUMN_NAME.ENCRYPTION_STATUS,
  cpi092: DEVICES_COLUMN_NAME.COMPLIANCE_STATUS,
  cpi093: DEVICES_COLUMN_NAME.APPLE_MANAGEMENT_STATUS,
  cpi094: DEVICES_COLUMN_NAME.SUPERVISION_STATUS
}

export const getColumnName = (columnName: string) => {
  if (columnName === DEVICES_COLUMN_NAME.ONYXIA_PRODUCT_INSTANCE) {
    return 'Source'
  }
  if (columnName === DEVICES_COLUMN_NAME.IP_ADDRESSES) {
    return 'IP Address'
  }
  if (columnName === DEVICES_COLUMN_NAME.UDID) {
    return 'Asset ID'
  }
  if (columnName === DEVICES_COLUMN_NAME.GROUP_NAME) {
    return 'Group'
  }
  if (columnName === DEVICES_COLUMN_NAME.MAC_ADDRESSES) {
    return 'MAC Address'
  }
  if (columnName === DEVICES_COLUMN_NAME.OS_NAME) {
    return 'OS Name'
  }
  if (columnName === DEVICES_COLUMN_NAME.OS_VERSION) {
    return 'OS Version'
  }
  if (columnName === DEVICES_COLUMN_NAME.USER_ID) {
    return 'User ID'
  }
  if (columnName === DEVICES_COLUMN_NAME.ENTITY_URL) {
    return 'Entity URL'
  }
  if (columnName === DEVICES_COLUMN_NAME.GROUP_IDS) {
    return 'Group IDs'
  }

  return columnName.toLowerCase().replaceAll('_', ' ')
}

export const formatCpiName = (name: string) => {
  return `${name.slice(0, 3).toUpperCase()}-${name.slice(-3)}`
}

export const getCpiSpecificFilters = (cpiName: string) => {
  const payload: any = {}

  if (cpiName === 'cpi091') {
    payload[DEVICES_COLUMN_NAME.ENCRYPTION_STATUS] = {
      eq_: false
    }
  }
  if (cpiName === 'cpi092') {
    payload[DEVICES_COLUMN_NAME.COMPLIANCE_STATUS] = {
      eq_: false
    }
  }
  if (cpiName === 'cpi093') {
    payload[DEVICES_COLUMN_NAME.APPLE_MANAGEMENT_STATUS] = {
      eq_: false
    }
  }
  if (cpiName === 'cpi094') {
    payload[DEVICES_COLUMN_NAME.SUPERVISION_STATUS] = {
      eq_: false
    }
  }

  return payload
}
