import { FC, SVGProps } from 'react'
import theme from 'theme'

const CloseDrawerIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M6 18L18 6M6 6L18 18"
      stroke={theme.colors.white}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default CloseDrawerIcon
