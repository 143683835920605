import { Box, Button } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const FilterChipWrapper = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  gap: ${theme.spacing.xSmall}px;

  &.reset-wrapper {
    align-self: flex-end;
  }

  .MuiTypography-root {
    color: ${theme.baseColors.secondary[80]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 22px;
    text-transform: capitalize;
  }
`

export const FilterButton = styled(Button)`
  border-radius: ${theme.attributes.borderRadius.tertiary};
  border: 1px solid ${theme.colors.borderMedium};
  gap: ${theme.spacing.xSmall}px;
  padding: 14px ${theme.spacing.xSmall}px 14px ${theme.spacing.small};
  height: 48px;
  min-height: 48px;
  color: ${theme.colors.textPrimary};
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.large};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 20px;
  text-transform: none;
  background: transparent;

  &.filter-chip-reset-button {
    color: ${theme.baseColors.greyscale[80]};
  }
`
