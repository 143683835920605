import { FC, ChangeEvent } from 'react'
import { Box, FormControlLabel, Checkbox } from '@mui/material'

import { DevicesColumns } from 'models'
import { getColumnName } from '../utils'

import { Popper } from './columnPicker.styles'
import { ThemeButton } from '../../../../../../components/buttons'

interface Props {
  anchorEl: HTMLElement | null
  open: boolean
  columns: DevicesColumns
  handleChangeColumns: (event: ChangeEvent<HTMLInputElement>) => void
  handleRestoreDefaultColumns: () => void
}

const ColumnPicker: FC<Props> = ({ anchorEl, open, columns, handleChangeColumns, handleRestoreDefaultColumns }) => (
  <Popper id="placement-popper" open={open} anchorEl={anchorEl} placement="left-start">
    <Box className="content">
      <Box className="list">
        {columns.map((column) => (
          <Box className="list-item" key={column.name}>
            <FormControlLabel
              className={`${column.active ? 'is-checked' : ''}`}
              control={
                <Checkbox
                  checked={column.active}
                  onChange={handleChangeColumns}
                  icon={
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="2.09961" y="2.10156" width="11.8" height="11.8" rx="3.5" stroke="#998D96" />
                    </svg>
                  }
                  checkedIcon={
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5.00146 8.50159L7.32896 10.8291C7.4182 10.9183 7.56678 10.9036 7.63679 10.7986L11.2515 5.37659M5.60156 2.10156H10.4016C12.3346 2.10156 13.9016 3.66857 13.9016 5.60156V10.4016C13.9016 12.3346 12.3346 13.9016 10.4016 13.9016H5.60156C3.66857 13.9016 2.10156 12.3346 2.10156 10.4016V5.60156C2.10156 3.66857 3.66857 2.10156 5.60156 2.10156Z"
                        stroke="white"
                        stroke-linecap="round"
                      />
                    </svg>
                  }
                />
              }
              label={getColumnName(column.name)}
              name={column.name}
            />
          </Box>
        ))}
      </Box>
      <ThemeButton themevariant="tertiary" className="restore-default-button" onClick={handleRestoreDefaultColumns}>
        Restore default
      </ThemeButton>
    </Box>
  </Popper>
)

export default ColumnPicker
