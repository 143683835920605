import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const DevicesTableContainer = styled(Box)`
  height: calc(100vh - 339px);
  padding: ${theme.spacing.medium}px;

  .ag-popup {
    border: 0;
    border-radius: ${theme.attributes.borderRadius.tertiary};
    background: #393038;

    .ag-menu.ag-filter-menu {
      border: 0;
      border-radius: ${theme.attributes.borderRadius.tertiary};
      background: #393038;

      .ag-filter {
        border: 0;
        border-radius: ${theme.attributes.borderRadius.tertiary};
        box-shadow: 0 4px 8px 0 ${theme.colors.primaryDark};
        width: 244px;

        .ag-filter-loading.ag-loading {
          background: #393038;

          span {
            color: ${theme.colors.white};
          }
        }

        .ag-filter-wrapper {
          border: 0;
          border-radius: ${theme.attributes.borderRadius.tertiary};
          background: #393038;

          .ag-filter-body-wrapper {
            border: 0;
            border-radius: ${theme.attributes.borderRadius.tertiary};
            background: #393038;

            .ag-picker-field.ag-labeled.ag-label-align-left.ag-select.ag-filter-select {
              .ag-wrapper.ag-picker-field-wrapper {
                background: #393038;
                color: ${theme.colors.white};
                border: 1px solid #564852;
              }
            }

            .ag-mini-filter.ag-labeled {
              margin: ${theme.spacing.small}px ${theme.spacing.small}px ${theme.spacing.xSmall}px
                ${theme.spacing.small}px;

              .ag-wrapper.ag-input-wrapper.ag-text-field-input-wrapper {
                &::before {
                  background: ${theme.baseColors.greyscale[80]};
                  opacity: 1;
                  mask-image: url('/search-mask-image.svg');
                  width: 20px;
                  height: 20px;
                  margin-left: ${theme.spacing.small}px;
                }

                input {
                  background: #261f25;
                  border: 1px solid #261f25;
                  border-radius: ${theme.attributes.borderRadius.mediumSmall};
                  padding-left: 48px;
                  color: ${theme.baseColors.greyscale[80]};

                  font-size: ${theme.typography.fontSize.large};
                  font-style: normal;
                  font-weight: ${theme.typography.fontWeight.normal};
                  line-height: 20px;

                  &:focus {
                    box-shadow: none;
                  }

                  &:focus-within {
                    border-color: ${theme.colors.textPrimary};
                  }

                  &::placeholder {
                    color: ${theme.baseColors.greyscale[80]};
                    font-size: ${theme.typography.fontSize.large};
                    font-style: normal;
                    font-weight: ${theme.typography.fontWeight.normal};
                    line-height: 20px;
                  }
                }
              }
            }

            .ag-set-filter-list {
              .ag-virtual-list-viewport {
                ::-webkit-scrollbar {
                  width: 4px;
                  background: transparent;
                }

                ::-webkit-scrollbar-track {
                  box-shadow: none;
                  background: transparent;
                }

                ::-webkit-scrollbar-thumb {
                  background-color: #292128;
                  border-radius: 2px;
                }
              }

              .ag-virtual-list-container.ag-filter-virtual-list-container {
                .ag-virtual-list-item.ag-filter-virtual-list-item {
                  cursor: pointer;

                  .ag-set-filter-item {
                    padding-left: ${theme.spacing.small}px !important;

                    .ag-set-filter-item-checkbox {
                      .ag-input-field-label {
                        color: ${theme.colors.controlBorder};
                        font-size: ${theme.typography.fontSize.large};
                        font-style: normal;
                        font-weight: ${theme.typography.fontWeight.bold};
                        line-height: 22px;
                      }

                      .ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
                        background: transparent;
                        border: 0.5px solid ${theme.colors.controlBorder};
                      }
                    }
                  }

                  &[aria-checked='true'] {
                    .ag-set-filter-item {
                      .ag-set-filter-item-checkbox {
                        .ag-input-field-label {
                          color: ${theme.colors.white};
                        }

                        .ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
                          border-color: ${theme.colors.white};
                        }
                      }
                    }
                  }
                }
              }
            }

            .ag-picker-field.ag-labeled.ag-select.ag-filter-select {
              //display: none;
            }

            .ag-filter-body {
              margin-bottom: 0;

              .ag-filter-from.ag-filter-date-from {
                .ag-react-container {
                  .react-datepicker-wrapper {
                    width: 100%;

                    .react-datepicker__input-container {
                      input {
                        height: 48px;
                        background: transparent;
                        padding: 14px ${theme.spacing.small}px;
                        width: 100%;
                        border-radius: ${theme.attributes.borderRadius.tertiary};
                        border: 1px solid #564852;
                        color: ${theme.colors.white};

                        font-size: ${theme.typography.fontSize.large};
                        font-style: normal;
                        font-weight: ${theme.typography.fontWeight.bold};
                        line-height: 20px;

                        &:focus-visible,
                        &:focus,
                        &:active,
                        &:focus-within {
                          border-color: ${theme.colors.textPrimary} !important;
                          outline: 0 !important;
                        }
                      }
                    }

                    .react-datepicker__calendar-icon {
                      right: 8px;
                      top: 8px;
                    }
                  }
                }
              }

              .ag-wrapper.ag-input-wrapper input {
                border-radius: ${theme.attributes.borderRadius.secondary};
                border: 1px solid #261f25;
                background: #261f25;
                color: ${theme.baseColors.greyscale[80]};
                font-size: ${theme.typography.fontSize.large};
                font-style: normal;
                font-weight: ${theme.typography.fontWeight.normal};
                line-height: 20px;

                &::placeholder {
                  color: ${theme.baseColors.greyscale[80]};
                  font-size: ${theme.typography.fontSize.large};
                  font-style: normal;
                  font-weight: ${theme.typography.fontWeight.normal};
                  line-height: 20px;
                }
              }
            }
          }

          .ag-filter-apply-panel {
            width: 100%;
            justify-content: flex-start;

            button {
              margin-left: 0;
              background: transparent;
              color: ${theme.colors.textPrimary};
              font-size: ${theme.typography.fontSize.large};
              font-style: normal;
              font-weight: ${theme.typography.fontWeight.extraThick};
              line-height: 22px;
              padding: ${theme.spacing.xSmall}px ${theme.spacing.small}px;
              border: 0;

              &:hover {
                background: ${theme.baseColors.secondary[30]};
                color: ${theme.colors.textPrimary};
              }
            }
          }
        }
      }
    }
  }

  .ag-root-wrapper {
    background: transparent;

    /* Date Picker Dropdown */
    .ag-popup {
      > * {
        background: #393038;
        color: ${theme.colors.white};
      }
    }

    .ag-root {
      border-radius: ${theme.attributes.borderRadius.tertiary};
      background: transparent;
      gap: ${theme.spacing.half}px;

      .ag-body-horizontal-scroll-viewport {
        overflow-x: auto;
      }

      .ag-header {
        border-radius: ${theme.attributes.borderRadius.tertiary};
        background: #393038;
        height: 50px;
        text-transform: capitalize;

        .ag-header-cell-label {
          .ag-sort-indicator-icon {
            .ag-icon {
              color: #d9bfd4;
            }
          }
        }

        .ag-header-row {
          .ag-header-cell {
            .ag-header-cell-comp-wrapper {
              .ag-cell-label-container {
                .ag-header-icon.ag-header-cell-filter-button {
                  .ag-icon.ag-icon-filter {
                    &::before {
                      mask-image: url('/devices-filter-icon.svg');
                    }
                  }

                  &.ag-has-popup-positioned-under {
                    .ag-icon.ag-icon-filter {
                      color: ${theme.colors.textPrimary};
                    }
                  }

                  &.ag-filter-active {
                    background: #594e58;
                    outline-color: #594e58;
                    border-radius: ${theme.attributes.borderRadius.small};

                    &:after {
                      background-color: ${theme.colors.textPrimary};
                      width: 5px;
                      height: 5px;
                      top: 0;
                      right: 0;
                    }

                    .ag-icon.ag-icon-filter {
                      color: ${theme.colors.textPrimary};
                      background: transparent;
                      outline: none;
                    }
                  }
                }
              }
            }

            .ag-header-cell-text {
              color: ${theme.baseColors.secondary[80]};
              overflow: hidden;
              text-overflow: ellipsis;
              font-family: ${theme.typography.fontFamily.primary};
              font-size: ${theme.typography.fontSize.large};
              font-style: normal;
              font-weight: ${theme.typography.fontWeight.normal};
              line-height: 22px;
            }
          }

          .ag-header-icon {
            color: ${theme.baseColors.secondary[80]};
          }
        }
      }

      .ag-body {
        .ag-body-viewport {
          .ag-full-width-container {
            .ag-row {
              background: #292328;
              border-radius: ${theme.attributes.borderRadius.tertiary};

              .ag-loading {
                color: ${theme.colors.white};
              }
            }
          }

          .ag-center-cols-container,
          .ag-pinned-left-cols-container,
          .ag-pinned-right-cols-container {
            .ag-row {
              border-radius: ${theme.attributes.borderRadius.tertiary};

              &.ag-row-even {
                background: ${theme.colors.primaryDark};
                border: 1px solid #292328;
              }

              &.ag-row-odd {
                background: #261f25;
                border: 1px solid #292328;
              }

              .ag-cell {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .MuiTypography-root {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-style: normal;
                  color: ${theme.colors.white};
                  font-family: ${theme.typography.fontFamily.primary};
                  font-size: ${theme.typography.fontSize.large};
                  font-weight: ${theme.typography.fontWeight.bold};
                }

                &[col-id='hostname'] {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
  }
`

export const ManagementStatusWrapper = styled(Box)`
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .filter-chips-container {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.md}px;
  }

  .controls-container {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.md}px;
  }
`

export const HeaderInfoWrapper = styled(Box)`
  padding: ${theme.spacing.small}px 0 ${theme.spacing.md}px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiTypography-root {
    color: ${theme.baseColors.secondary[80]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 22px;

    span {
      color: ${theme.colors.white};
    }
  }
`
