import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const SearchBarWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: ${theme.spacing.xSmall}px;

  .search-icon {
    position: absolute;
    left: 26px;
    top: 18px;
    z-index: 1;
  }

  .clear-btn {
    position: absolute;
    right: ${theme.spacing.lg}px;
  }

  .search-bar {
    width: 100%;
    border-radius: ${theme.attributes.borderRadius.secondary};
    height: 36px;
  }

  .MuiOutlinedInput-input {
    border: 1px solid #261f25;
    border-radius: ${theme.attributes.borderRadius.secondary};
    background: #261f25;
    height: 36px;
    padding: 0 48px;
    color: ${theme.baseColors.greyscale[80]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 20px;

    &::placeholder {
      color: ${theme.baseColors.greyscale[80]};
      opacity: 1;
    }

    &:focus {
      border-color: ${theme.colors.textPrimary};
    }
  }

  fieldset {
    border: none;
  }
`
