import { Dispatch, FC, SetStateAction, useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'

/* Utils */
import { DEVICES_COLUMN_NAME, DevicesDataItem } from 'models'

/* Components */
import { Drawer } from './dataDrawer.styles'
import RowItem from './RowItem'
import { CloseDrawerIcon } from 'ui/components/svg'

const rows = [
  { title: 'Source', name: DEVICES_COLUMN_NAME.ONYXIA_PRODUCT_NAME },
  { title: 'Asset ID', name: DEVICES_COLUMN_NAME.UDID },
  { title: 'Hostname', name: DEVICES_COLUMN_NAME.HOSTNAME },
  { title: 'Asset Type', name: DEVICES_COLUMN_NAME.ASSET_TYPE },
  { title: 'Username', name: DEVICES_COLUMN_NAME.USER_NAME },
  { title: 'User Email', name: DEVICES_COLUMN_NAME.EMAIL_ADDRESS },
  { title: 'Group', name: DEVICES_COLUMN_NAME.GROUP_NAME },
  { title: 'First Seen', name: DEVICES_COLUMN_NAME.FIRST_SEEN },
  { title: 'Last Seen', name: DEVICES_COLUMN_NAME.LAST_SEEN },
  { title: 'OS Name', name: DEVICES_COLUMN_NAME.OS_NAME },
  { title: 'OS Version', name: DEVICES_COLUMN_NAME.OS_VERSION },
  { title: 'MAC Address', name: DEVICES_COLUMN_NAME.MAC_ADDRESSES },
  { title: 'IP Address', name: DEVICES_COLUMN_NAME.IP_ADDRESSES },
  { title: 'Apple Management Status', name: DEVICES_COLUMN_NAME.APPLE_MANAGEMENT_STATUS }
]

interface Props {
  drawerOpen: boolean
  activeRowItem: DevicesDataItem | null
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
}

const DataDrawer: FC<Props> = ({ drawerOpen, activeRowItem, setDrawerOpen }) => {
  const [showMoreOpen, setShowMoreOpen] = useState(false)

  if (!activeRowItem) return <></>
  return (
    <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
      <Box role="presentation">
        <Box className="wrapper-inner">
          <Box className="header">
            <Typography className="title">Device information</Typography>
            <IconButton className="close-icon" onClick={() => setDrawerOpen(false)}>
              <CloseDrawerIcon />
            </IconButton>
          </Box>

          <Box className="content">
            {rows.map((row, idx) => {
              const item = activeRowItem[row.name]

              if (
                row.name === DEVICES_COLUMN_NAME.IP_ADDRESSES &&
                typeof item === 'string' &&
                item.includes(']') &&
                item.includes(',')
              ) {
                const parsedArr: string[] = JSON.parse(item)
                const collapsedArr: string[] = parsedArr.slice(0, 3)

                return (
                  <Box className="row-item">
                    <Typography className="row-name">{row.title}</Typography>
                    <Box className="row-data">
                      {parsedArr.length > 3 ? (
                        <>
                          {!showMoreOpen
                            ? collapsedArr.map((item) => <RowItem key={item} text={item} />)
                            : parsedArr.map((item) => <RowItem key={item} text={item} />)}
                        </>
                      ) : (
                        parsedArr.length <= 3 && parsedArr.map((item) => <RowItem key={item} text={item} />)
                      )}

                      {parsedArr.length > 3 && (
                        <Typography className="show-more-button" onClick={() => setShowMoreOpen(!showMoreOpen)}>
                          {showMoreOpen ? 'show less' : 'show more'}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                )
              }
              if (item) {
                return (
                  <Box className="row-item" key={row.name}>
                    <Typography className="row-name">{row.title}</Typography>
                    <Box className="row-data">
                      <RowItem
                        integrationName={idx === 0 ? (item as string) : undefined}
                        text={typeof item === 'boolean' ? (item ? 'Managed' : 'Non-Managed') : item}
                      />
                    </Box>
                  </Box>
                )
              }

              return null
            })}
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}

export default DataDrawer
