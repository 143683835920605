import { Box, Button } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const SourceCard = styled(Box)`
  padding: ${theme.spacing.xSmall}px;
  display: flex;
  background: ${theme.colors.backgroundMedium};
  box-shadow: ${theme.attributes.boxShadow.primary};
  border-radius: ${theme.attributes.borderRadius.quaternary};
  width: 100%;

  @media screen and (min-width: 1600px) {
    flex-basis: 49%;
    width: 49%;
  }

  img {
    overflow: visible !important;
  }

  .source-card-additional {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .source-card-button {
      min-width: 196px !important;
    }

    .additional-text-wrapper {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: flex-start;
      flex-basis: 40%;

      .source-card-header {
        margin-left: ${theme.spacing.small}px;
        color: ${theme.baseColors.greyscale[80]};
        font-size: ${theme.typography.fontSize.mediumSmall};
        font-family: ${theme.typography.fontFamily.primary};
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 16px;
        letter-spacing: 0.5px;
      }
    }

    .MuiButtonBase-root {
      margin-top: ${theme.spacing.xSmall}px !important;
      margin-left: ${theme.spacing.xSmall}px;
      background: ${theme.colors.primaryDarker};

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }
  }

  .source-card-title {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.xLarge};
    line-height: 24px;
    letter-spacing: 0.5px;
    color: ${theme.colors.white};
    margin-left: ${theme.spacing.small}px;
    text-transform: capitalize;
  }

  .source-card-text {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.medium};
    line-height: 16px;
    letter-spacing: 0.4px;
    color: ${theme.colors.white};
    margin-top: ${theme.spacing.xSmall}px;
    margin-left: ${theme.spacing.small}px;
  }

  .tooltip-text {
    color: ${theme.colors.neutral} !important;
    font-size: ${theme.typography.fontSize.large} !important;
    font-family: ${theme.typography.fontFamily.primary};
    line-height: 20px !important;
    letter-spacing: 0.25px !important;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const FormulaWrapper = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  box-shadow: ${theme.attributes.boxShadow.primary};
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));
  margin-left: ${theme.spacing.small}px;

  .image-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: ${theme.spacing.small}px;
    background: ${theme.colors.backgroundMedium};
    box-shadow: ${theme.attributes.boxShadow.tertiary};
    border-radius: ${theme.attributes.borderRadius.tertiary} ${theme.attributes.borderRadius.tertiary}
      ${theme.attributes.borderRadius.tertiary} ${theme.attributes.borderRadius.tertiary};
    border: 1px solid rgba(147, 143, 153, 0.16);
    border-top: none;

    img {
      width: 100%;
      max-height: 100px;
      margin-top: 0 !important;
    }
  }
`

export const DataSourceButton = styled(Button)<{ isactive: string }>`
  font-family: ${theme.typography.fontFamily.primary};
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: ${theme.typography.fontSize.large};
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: ${theme.colors.white};
  border-radius: ${theme.attributes.borderRadius.secondary};
  background: transparent;
  padding: 0;
  min-width: 174px;
  text-transform: none;
  height: 32px;
  margin-right: ${theme.spacing.small}px;
  border: 1px solid ${theme.colors.controlBorder};

  ${({ isactive }) =>
    isactive &&
    isactive === 'true' &&
    `
      background: ${theme.colors.primaryLight};
      border: 0;
      color: #FBD8F5;
  `};
`

export const AboutDescriptionWrapper = styled(Box)<{ isopen: string }>`
  margin-left: ${theme.spacing.medium}px;
  max-width: 64%;

  ${({ isopen }) =>
    isopen === 'false' &&
    `
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-flow: column wrap;
      margin: ${theme.spacing.medium}px auto;
      transition: all .5s ease;
  `};

  ${({ isopen }) =>
    isopen === 'true' &&
    `
      display: block;
      transition: all .5s ease;
  `};

  article {
    background: transparent;
    padding: 0 ${theme.spacing.small}px ${theme.spacing.small}px 0;
    margin-bottom: ${theme.spacing.xLarge}px;
    border-bottom: 1px solid rgba(147, 143, 153, 0.12);
    width: 100%;

    .truncate-wrapper {
      padding-left: ${theme.spacing.small}px;
    }

    .title {
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.xxLarge};
      line-height: 28px;
      color: ${theme.colors.white};
      margin: 0 0 ${theme.spacing.xSmall}px;
    }

    h2 {
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.xLarge};
      line-height: 24px;
      letter-spacing: 0.1px;
      margin-bottom: 0;
      margin-top: 0;
    }

    h3 {
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.xLarge};
      line-height: 24px;
      letter-spacing: 0.1px;
      color: ${theme.colors.white};
      padding-left: ${theme.spacing.small}px;
      margin: 0 0 ${theme.spacing.md}px;

      &:not(:first-of-type) {
        margin-top: ${theme.spacing.medium}px;
      }
    }

    p {
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.medium};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 16px;
      letter-spacing: 0.4px;
      color: ${theme.colors.white};
      margin-top: 0;
      margin-bottom: ${theme.spacing.xSmall}px;
    }

    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      margin-top: ${theme.spacing.xSmall}px;

      .MuiButtonBase-root {
        padding: ${theme.spacing.xsm}px ${theme.spacing.small}px !important;
        min-width: 135px !important;

        &.expanded-icon {
          svg {
            transform: rotate(45deg);
            transition: transform 0.5s ease;
          }
        }

        &.collapsed-icon {
          svg {
            transform: rotate(0);
            transition: transform 0.5s ease;
          }
        }
      }
    }

    img {
      margin-top: ${theme.spacing.lg}px;
    }

    .display-linebreak {
      white-space: pre-line;
      padding-left: ${theme.spacing.small}px;
      line-height: 4px;
    }
  }
`

export const AboutContentWrapper = styled(Box)<{ isopen: string }>`
  background: transparent;
  padding: ${theme.spacing.small}px ${theme.spacing.small}px ${theme.spacing.small}px 0;
  margin-top: 0 !important;
  margin-bottom: ${theme.spacing.medium}px;
  border-bottom: 1px solid rgba(147, 143, 153, 0.12);
  margin-left: ${theme.spacing.medium}px;
  max-width: 64%;
  width: 100%;

  ${({ isopen }) =>
    isopen === 'false' &&
    `
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-flow: column wrap;
      margin: ${theme.spacing.medium}px auto;
      transition: all .5s ease;
  `};

  ${({ isopen }) =>
    isopen === 'true' &&
    `
      display: block;
      transition: all .5s ease;
  `};

  .data-source-wrapper {
    display: flex;
    align-items: center;
    margin-top: 22px;
    margin-bottom: ${theme.spacing.md}px;
    margin-left: ${theme.spacing.small}px;
  }

  .card-description {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.large};
    line-height: 20px;
    letter-spacing: 0.25px;
    color: ${theme.colors.white};
    padding-left: ${theme.spacing.small}px;
  }

  .source-card-wrapper {
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    gap: ${theme.spacing.small}px;
    width: 100%;
    margin-top: ${theme.spacing.md}px;
    margin-left: ${theme.spacing.small}px;

    @media screen and (min-width: 1600px) {
      flex-flow: row wrap;
    }
  }

  .title {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.xxLarge};
    line-height: 28px;
    color: ${theme.colors.neutral};
    margin-bottom: ${theme.spacing.half}px;
  }

  .description {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    color: ${theme.colors.neutral};
    letter-spacing: 0.25px;
    font-size: ${theme.typography.fontSize.large};
    line-height: 20px;
  }

  img:not(:last-of-type) {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    margin-top: ${theme.spacing.lg}px;
  }
`
