import { Dispatch, FC, SetStateAction, useState } from 'react'
import { Box, Typography, ClickAwayListener, SelectProps } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import theme from 'theme'

/* Utils */
import useSearchLabel from 'hooks/useSearchLabel'
import { categoryToUrl, urlToCategoryCapitalized } from 'ui/pages/library/utils'
import { formatCPIDisplayNameForURL } from 'lib/utils'
import { CPILibraryItem, IChartDataCategory } from 'models'

/* Components */
import { Wrapper, Popper } from './cpiNavigation.styles'
import { CpiNavigationHoverTooltip } from 'ui/pages/library/library-details/components/LibraryDetailsTooltip'
import CpiNavigationSearch from './CpiNavigationSearch'
import { formatCpiName } from '../utils'

interface Props extends SelectProps {
  options: CPILibraryItem[] | IChartDataCategory[]
  setInitialLoad?: Dispatch<SetStateAction<boolean>>
  setRenderingChart?: Dispatch<SetStateAction<boolean>>
  version: string
  callback?: () => void
  setShowMessage?: Dispatch<SetStateAction<boolean>>
  cpiName: string
}

const CpiNavigation: FC<Props> = ({
  options,
  cpiName,
  version,
  setInitialLoad,
  setRenderingChart,
  callback,
  setShowMessage,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [popperOpen, setPopperOpen] = useState(false)
  const navigate = useNavigate()

  const convertedOptions = options.map((item) => {
    if (version === 'cpi' && 'display_name' in item && 'title' in item) {
      return {
        label: item.display_name,
        name: item.title
      }
    } else if ('score' in item) {
      return {
        label: urlToCategoryCapitalized(item.category),
        name: urlToCategoryCapitalized(item.category)
      }
    }
    return { label: '', name: '' }
  })

  const { search, setSearch, labelOptions, highlightMatch } = useSearchLabel(convertedOptions, null, true)

  return (
    <ClickAwayListener
      onClickAway={() => {
        setPopperOpen(false)
        setAnchorEl(null)
      }}
    >
      <Wrapper className="cpi-navigation-wrapper">
        <Box className="cpi-name-wrapper" onClick={() => setPopperOpen(!popperOpen)}>
          <Typography className="navigation-cpi-name" ref={(elm) => setAnchorEl(elm)}>
            {formatCpiName(cpiName)}
          </Typography>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              transform: popperOpen ? 'rotate(180deg)' : '',
              transition: 'all .2s ease-in-out'
            }}
          >
            <path
              d="M9.75 4.125L6 7.875L2.25 4.125"
              stroke={theme.colors.textPrimary}
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Box>

        <Popper id="cpi-navigation-popper" open={popperOpen} anchorEl={anchorEl} placement="bottom-end">
          <Box className="content">
            <CpiNavigationSearch search={search} setSearch={setSearch} />

            <Box className="list-wrapper">
              <Box className="list">
                {labelOptions.map((option) => (
                  <CpiNavigationHoverTooltip
                    title={version === 'cpi' ? option.label + ' ' + option.name : option.label}
                    arrow
                    placement="right"
                    key={option.label}
                  >
                    <Box
                      className="list-item"
                      onClick={() => {
                        if (!rest.multiple) {
                          setAnchorEl(null)
                        }
                        if (setInitialLoad) {
                          setInitialLoad(false)
                        }
                        if (setRenderingChart) {
                          setRenderingChart(true)
                        }
                        if (callback) {
                          callback()
                        }
                        if (setShowMessage) {
                          setShowMessage(false)
                        }
                        navigate(
                          `/library/${
                            version === 'cpi'
                              ? `cpis/${formatCPIDisplayNameForURL(option.label)}`
                              : `categories/${categoryToUrl(option.label)}`
                          }`
                        )
                      }}
                    >
                      <Typography className="list-item-text">
                        {highlightMatch(version === 'cpi' ? option.label + ' ' + option.name : option.label)}
                      </Typography>
                    </Box>
                  </CpiNavigationHoverTooltip>
                ))}
              </Box>
            </Box>
          </Box>
        </Popper>
      </Wrapper>
    </ClickAwayListener>
  )
}

export default CpiNavigation
