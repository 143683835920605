import { FC } from 'react'
import { Box, Typography } from '@mui/material'

const getIntegrationLogo = (name: string) => {
  if (name) {
    return `/icons/circle/${name}_icon.svg`
  }

  return '/icons/circle/crowdstrike_falcon_icon.svg'
}

interface Props {
  text: string
  lastSeenMissing?: boolean
  integrationName?: string | null
}

const RowItem: FC<Props> = ({ text, lastSeenMissing, integrationName }) => (
  <Box className="data-item">
    {integrationName && (
      <img
        src={getIntegrationLogo(integrationName || '')}
        alt=""
        width="24px !important"
        height="24px !important"
        style={{ minWidth: '24px !important' }}
      />
    )}
    <Typography className={`name ${lastSeenMissing ? 'empty-cell' : ''}`}>{lastSeenMissing ? '━━' : text}</Typography>
  </Box>
)

export default RowItem
