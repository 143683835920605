import { Popper as MuiPopper } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Popper = styled(MuiPopper)`
  width: 280px;
  flex-shrink: 0;
  border-radius: ${theme.attributes.borderRadius.tertiary};
  background: #393038;
  box-shadow: 0 4px 8px 0 rgba(30, 26, 29, 0.5);
  z-index: 1000000000;
  margin-right: ${theme.spacing.half}px !important;

  .content {
    padding: ${theme.spacing.md}px 0;

    .restore-default-button {
      border-radius: ${theme.attributes.borderRadius.tertiary} !important;
      font-size: ${theme.typography.fontSize.large} !important;
      font-weight: ${theme.typography.fontWeight.extraThick} !important;
      line-height: 22px !important;
      padding: ${theme.spacing.xSmall}px 24px !important;
    }

    .list {
      display: flex;
      flex-flow: column wrap;

      .list-item {
        width: 100%;
        height: 38px;
        gap: ${theme.spacing.md}px;

        .MuiFormControlLabel-root {
          display: flex;
          align-items: center;
          padding: ${theme.spacing.xSmall}px ${theme.spacing.medium}px;
          margin: 0;

          .MuiCheckbox-root {
            padding: 0 ${theme.spacing.xSmall}px 0 0;
          }

          .MuiTypography-root {
            color: ${theme.colors.controlBorder};
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.large};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.bold};
            line-height: 22px;
            text-transform: capitalize;
          }

          &.is-checked .MuiTypography-root {
            color: ${theme.colors.white};
          }
        }
      }
    }
  }
`
