import * as React from 'react'
import ReactDatePicker from 'react-datepicker'
import '../../../../../../../node_modules/react-datepicker/dist/react-datepicker.css'

export const DTPicker = React.forwardRef<unknown, any>((props, ref) => {
  const [date, setDate] = React.useState<Date | null>(null)

  console.log('date: ', date)
  console.log('props: ', props)
  const onDateChanged = (selectedDate: Date) => {
    setDate(selectedDate)
    props.onDateChange(selectedDate)
  }

  React.useImperativeHandle(ref, () => ({
    getDate() {
      return date
    },

    setDate(date: Date | null) {
      setDate(date)
    }
  }))

  return (
    <ReactDatePicker
      portalId="root"
      placeholderText="dd-MM-yyyy"
      dateFormat="dd-MM-yyyy"
      popperClassName="ag-custom-component-popup datepicker-filter-popper"
      selected={date}
      onChange={onDateChanged as any}
      enableTabLoop
      strictParsing
      shouldCloseOnSelect={true}
      useWeekdaysShort={true}
      showIcon={true}
      icon={
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path
            d="M5.0625 2.25024V3.93774M12.9375 2.25024V3.93774M2.25 14.0627V5.62524C2.25 4.69326 3.00552 3.93774 3.9375 3.93774H14.0625C14.9945 3.93774 15.75 4.69326 15.75 5.62524V14.0627M2.25 14.0627C2.25 14.9947 3.00552 15.7502 3.9375 15.7502H14.0625C14.9945 15.7502 15.75 14.9947 15.75 14.0627M2.25 14.0627V8.43774C2.25 7.50576 3.00552 6.75024 3.9375 6.75024H14.0625C14.9945 6.75024 15.75 7.50576 15.75 8.43774V14.0627"
            stroke="#FFA9FC"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      }
    />
  )
})
