import { FC, Dispatch, SetStateAction, useEffect, useState, useMemo } from 'react'
import { Wrapper } from './reportsTimePeriodPicker.styles'
import { SelectChangeEvent } from '@mui/material'
import { MonthRangePicker } from '@khan_skadi/month-range-picker'
import { CPITimePeriod } from '../../../../../models'
import ReportsTimePeriodSelectField from './ReportsTimePeriodSelectField'

const numDays = (y: number, m: number) => new Date(y, m, 0).getDate()

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

type RangeParams = {
  startMonth: number
  startYear: number
  endMonth: number
  endYear: number
}

interface Props {
  timePeriodOptions: Array<CPITimePeriod | string>
  selectedTimePeriod: CPITimePeriod | string
  setSelectedTimePeriod: Dispatch<SetStateAction<CPITimePeriod | string>>
  handleChangeTimePeriod: (event: SelectChangeEvent | null, customPayload?: any) => void
  setCustomTimePeriodValue: Dispatch<
    SetStateAction<{
      dateFrom: string
      dateTo: string
    }>
  >
}

const ReportsTimePeriodPicker: FC<Props> = ({
  timePeriodOptions,
  selectedTimePeriod,
  handleChangeTimePeriod,
  setSelectedTimePeriod,
  setCustomTimePeriodValue
}) => {
  const [pickerOpen, setPickerOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    if (!anchorEl) {
      closeCustomPicker()
    }
  }, [anchorEl])

  const onRangeSelect = (params: RangeParams) => {
    const { startMonth, endMonth, startYear, endYear } = params
    const stringMonthStart = startMonth.toString().padStart(2, '0')
    const stringMonthEnd = endMonth.toString().padStart(2, '0')

    if (startMonth && startYear) {
      const start = `${startYear}-${stringMonthStart}-01`
      setCustomTimePeriodValue((pre) => ({
        ...pre,
        dateFrom: start
      }))
    }
    if (endMonth && endYear) {
      const days = numDays(endYear, endMonth)
      const end = `${endYear}-${stringMonthEnd}-${days}`
      setSelectedTimePeriod(`${months[startMonth - 1]} ${startYear} - ${months[endMonth - 1]} ${endYear}`)
      setCustomTimePeriodValue({
        dateFrom: `${startYear}-${stringMonthStart}-01`,
        dateTo: end
      })
    }
  }

  const handleCustomClick = () => {
    setPickerOpen(!pickerOpen)
  }

  const closeCustomPicker = () => {
    setPickerOpen(false)
  }

  const onChangeCallback = (month: any, startMonth: any, endMonth: any) => {
    if (!startMonth || endMonth) {
      //
    } else if (month < startMonth) {
      //
    } else {
      setAnchorEl(null)
      closeCustomPicker()
    }
  }

  const renderMonthPicker = useMemo(
    () => (
      <MonthRangePicker
        onRangeSelect={onRangeSelect}
        calendarClassName={`month-picker-container ${anchorEl && pickerOpen && 'isopen'}`}
        headerClassName="month-picker-header"
        monthClassName="month-picker-month"
        columns={2}
        initialYear={new Date().getFullYear() - 1}
        onChangeCallback={onChangeCallback}
      />
    ),
    [pickerOpen]
  )

  return (
    <Wrapper>
      <ReportsTimePeriodSelectField
        options={timePeriodOptions}
        value={selectedTimePeriod}
        handleChange={handleChangeTimePeriod}
        disabled={false}
        handleCustomClick={handleCustomClick}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        closeCustomPicker={closeCustomPicker}
        pickerOpen={pickerOpen}
      />
      {renderMonthPicker}
    </Wrapper>
  )
}

export default ReportsTimePeriodPicker
