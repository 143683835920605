import { FC } from 'react'
import TabPanel from './components/LibraryDetailsTabPanel'
import TrendsTab from './sections/trends-tab'
import AboutTab from './sections/about-tab'
import BenchmarkingTab from './sections/benchmarking-tab'
import Devices from './sections/devices/Devices'

interface Props {
  devicesManifest: any
  devicesCpiSupported: any
  devicesIntegrationSupported: any
  thisCpiName: any
  fetchQuery: any
  showBenchmarkingTab: any
  criticalSla: any
  initialLoad: any
  showMessage: any
  healthDirection: any
  competitorAnalysisData: any
  detailedComparisonData: any
  activeSources: any
  notConfiguredInstancesApplicableToCpi: any
  getIntegrationLogo: any
  drawerOpen: any
  tabValue: any
  active: any
  cpi: any
  setPredictionData: any
  setShowMessage: any
  fetchPredictionData: any
  stripes: any
  setStripes: any
  fetchingInterval: any
  isMediumHeight: any
  manifestData: any
  chartData: any
  setChartData: any
  setSelectedType: any
  setSelectedSeverity: any
  setSelectedTimePeriod: any
  setSelectedFrequency: any
  setDataSourceValue: any
  selectedType: any
  selectedTimePeriod: any
  selectedSeverity: any
  selectedFrequency: any
  userConfiguredIntegrations: any
  cpiConfiguration: any
  cpiSupportedAndUserConfiguredList: any
  timePeriodValues: any
  frequencyValues: any
  dataSourceOptions: any
  dataSourceValue: any
  setEmptyInfoTooltipOpen: any
  setCurrentSeverityStatus: any
  emptyInfoTooltipOpen: any
  labelDrawerOpen: any
  setLabelDrawerOpen: any
  mode: any
  setMode: any
  handleEmptyInfoClick: any
  currentSeverityStatus: any
  redirectToMode: any
  setRedirectToMode: any
  defaultValues: any
  setHealthDirection: any
  setCriticalSla: any
  setFetchingInterval: any
  selectedDataSourceIds: any
  setSelectedDataSourceIds: any
  customTimePeriodValue: any
  setCustomTimePeriodValue: any
  predictionModalOpen: any
  handlePredictionModalOpen: any
  hasPredictionsEnabled: any
  predictionModelAvailable: any
  refetchPredictionData: any
  daysUntilDataAvailable: any
  renderingChart: any
  setRenderingChart: any
  customerName: string
}

const TabPanels: FC<Props> = ({
  active,
  cpi,
  setPredictionData,
  setShowMessage,
  fetchPredictionData,
  stripes,
  devicesCpiSupported,
  devicesIntegrationSupported,
  thisCpiName,
  devicesManifest,
  fetchQuery,
  setStripes,
  fetchingInterval,
  isMediumHeight,
  manifestData,
  showBenchmarkingTab,
  criticalSla,
  initialLoad,
  showMessage,
  healthDirection,
  competitorAnalysisData,
  detailedComparisonData,
  chartData,
  setChartData,
  setSelectedType,
  setSelectedSeverity,
  setSelectedTimePeriod,
  setSelectedFrequency,
  setDataSourceValue,
  selectedType,
  selectedTimePeriod,
  selectedSeverity,
  selectedFrequency,
  userConfiguredIntegrations,
  cpiConfiguration,
  cpiSupportedAndUserConfiguredList,
  timePeriodValues,
  frequencyValues,
  dataSourceOptions,
  dataSourceValue,
  setEmptyInfoTooltipOpen,
  setCurrentSeverityStatus,
  emptyInfoTooltipOpen,
  labelDrawerOpen,
  setLabelDrawerOpen,
  mode,
  setMode,
  handleEmptyInfoClick,
  currentSeverityStatus,
  redirectToMode,
  setRedirectToMode,
  defaultValues,
  setHealthDirection,
  setCriticalSla,
  setFetchingInterval,
  selectedDataSourceIds,
  setSelectedDataSourceIds,
  customTimePeriodValue,
  setCustomTimePeriodValue,
  predictionModalOpen,
  handlePredictionModalOpen,
  hasPredictionsEnabled,
  predictionModelAvailable,
  refetchPredictionData,
  daysUntilDataAvailable,
  renderingChart,
  setRenderingChart,
  tabValue,
  activeSources,
  notConfiguredInstancesApplicableToCpi,
  getIntegrationLogo,
  drawerOpen,
  customerName
}) => {
  if (active) {
    return (
      <>
        <TabPanel value={tabValue} index={0}>
          <TrendsTab
            cpi={cpi}
            setPredictionData={setPredictionData}
            setShowMessage={setShowMessage}
            fetchPredictionData={fetchPredictionData}
            stripes={stripes}
            setStripes={setStripes}
            fetchingInterval={fetchingInterval}
            isMediumHeight={isMediumHeight}
            manifestData={manifestData}
            chartData={chartData}
            setChartData={setChartData}
            setSelectedType={setSelectedType}
            setSelectedSeverity={setSelectedSeverity}
            setSelectedTimePeriod={setSelectedTimePeriod}
            setSelectedFrequency={setSelectedFrequency}
            setDataSourceValue={setDataSourceValue}
            selectedType={selectedType}
            selectedTimePeriod={selectedTimePeriod}
            selectedSeverity={selectedSeverity}
            selectedFrequency={selectedFrequency}
            userConfiguredIntegrations={userConfiguredIntegrations}
            cpiConfiguration={cpiConfiguration}
            cpiSupportedAndUserConfiguredList={cpiSupportedAndUserConfiguredList}
            timePeriodValues={timePeriodValues}
            frequencyValues={frequencyValues}
            dataSourceOptions={dataSourceOptions}
            dataSourceValue={dataSourceValue}
            setEmptyInfoTooltipOpen={setEmptyInfoTooltipOpen}
            setCurrentSeverityStatus={setCurrentSeverityStatus}
            emptyInfoTooltipOpen={emptyInfoTooltipOpen}
            labelDrawerOpen={labelDrawerOpen}
            setLabelDrawerOpen={setLabelDrawerOpen}
            mode={mode}
            setMode={setMode}
            handleEmptyInfoClick={handleEmptyInfoClick}
            currentSeverityStatus={currentSeverityStatus}
            redirectToMode={redirectToMode}
            setRedirectToMode={setRedirectToMode}
            defaultValues={defaultValues}
            setHealthDirection={setHealthDirection}
            setCriticalSla={setCriticalSla}
            setFetchingInterval={setFetchingInterval}
            selectedDataSourceIds={selectedDataSourceIds}
            setSelectedDataSourceIds={setSelectedDataSourceIds}
            customTimePeriodValue={customTimePeriodValue}
            setCustomTimePeriodValue={setCustomTimePeriodValue}
            predictionModalOpen={predictionModalOpen}
            handlePredictionModalOpen={handlePredictionModalOpen}
            hasPredictionsEnabled={hasPredictionsEnabled}
            predictionModelAvailable={predictionModelAvailable}
            refetchPredictionData={refetchPredictionData}
            daysUntilDataAvailable={daysUntilDataAvailable}
            renderingChart={renderingChart}
            setRenderingChart={setRenderingChart}
          />
        </TabPanel>
        <>
          {devicesManifest && devicesCpiSupported && devicesIntegrationSupported ? (
            <>
              <TabPanel value={tabValue} index={1}>
                <Devices
                  thisCpiName={thisCpiName}
                  devicesManifest={devicesManifest}
                  fetchQuery={fetchQuery}
                  customerName={customerName}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <AboutTab
                  manifest={manifestData}
                  cpi={cpi}
                  activeSources={activeSources}
                  notConfiguredInstancesApplicableToCpi={notConfiguredInstancesApplicableToCpi}
                  additionalOptions={cpi?.supported_integrations || []}
                  getIntegrationLogo={getIntegrationLogo}
                  active={active}
                  drawerOpen={drawerOpen}
                />
              </TabPanel>
              {showBenchmarkingTab && (
                <TabPanel value={tabValue} index={3}>
                  <BenchmarkingTab
                    active
                    sla={criticalSla}
                    unit={manifestData.ui.trend_chart.user_input.visualization_param.unit}
                    benchmarkingTabLoading={!initialLoad}
                    showMessage={showMessage}
                    setShowMessage={setShowMessage}
                    healthDirection={healthDirection}
                    competitorAnalysisData={competitorAnalysisData}
                    detailedComparisonData={detailedComparisonData}
                    setCriticalSla={setCriticalSla}
                    selectedSeverity={selectedSeverity}
                    selectedDataSourceIds={selectedDataSourceIds}
                    selectedFrequency={selectedFrequency}
                    cpi={cpi}
                    selectedTimePeriod={selectedTimePeriod}
                    manifestData={manifestData}
                  />
                </TabPanel>
              )}
            </>
          ) : (
            <>
              <TabPanel value={tabValue} index={1}>
                <AboutTab
                  manifest={manifestData}
                  cpi={cpi}
                  activeSources={activeSources}
                  notConfiguredInstancesApplicableToCpi={notConfiguredInstancesApplicableToCpi}
                  additionalOptions={cpi?.supported_integrations || []}
                  getIntegrationLogo={getIntegrationLogo}
                  active={active}
                  drawerOpen={drawerOpen}
                />
              </TabPanel>
              {showBenchmarkingTab && (
                <TabPanel value={tabValue} index={2}>
                  <BenchmarkingTab
                    active
                    sla={criticalSla}
                    unit={manifestData.ui.trend_chart.user_input.visualization_param.unit}
                    benchmarkingTabLoading={!initialLoad}
                    showMessage={showMessage}
                    setShowMessage={setShowMessage}
                    healthDirection={healthDirection}
                    competitorAnalysisData={competitorAnalysisData}
                    detailedComparisonData={detailedComparisonData}
                    setCriticalSla={setCriticalSla}
                    selectedSeverity={selectedSeverity}
                    selectedDataSourceIds={selectedDataSourceIds}
                    selectedFrequency={selectedFrequency}
                    cpi={cpi}
                    selectedTimePeriod={selectedTimePeriod}
                    manifestData={manifestData}
                  />
                </TabPanel>
              )}
            </>
          )}
        </>
      </>
    )
  }
  return (
    <>
      <TabPanel value={tabValue} index={0}>
        <AboutTab
          manifest={manifestData}
          cpi={cpi}
          activeSources={cpiSupportedAndUserConfiguredList}
          notConfiguredInstancesApplicableToCpi={notConfiguredInstancesApplicableToCpi}
          additionalOptions={cpi?.supported_integrations || []}
          getIntegrationLogo={getIntegrationLogo}
          active={active}
          drawerOpen={drawerOpen}
        />
      </TabPanel>
      {showBenchmarkingTab && (
        <TabPanel value={tabValue} index={1}>
          <BenchmarkingTab
            sla={criticalSla}
            cpi={cpi}
            unit={manifestData.ui.trend_chart.user_input.visualization_param.unit}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            benchmarkingTabLoading={!initialLoad}
            healthDirection={healthDirection}
            competitorAnalysisData={competitorAnalysisData}
            detailedComparisonData={detailedComparisonData}
            setCriticalSla={setCriticalSla}
            selectedSeverity={selectedSeverity}
            selectedDataSourceIds={selectedDataSourceIds}
            selectedFrequency={selectedFrequency}
            selectedTimePeriod={selectedTimePeriod}
            manifestData={manifestData}
          />
        </TabPanel>
      )}
    </>
  )
}

export default TabPanels
