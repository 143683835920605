import { ChangeEvent, Dispatch, FC, SetStateAction, useMemo, useState } from 'react'
import { Box, CircularProgress, IconButton, TextField, Typography } from '@mui/material'
import debounce from 'lodash.debounce'
import axios from '../../../../../lib/axios'
import theme from 'theme'

/* Utils */
import { SSMAssetType, SSMNistPillar, Vendor } from '../../../../../models'
import { DrawerProps } from '../../SSM'

/* Components */
import {
  AutocompleteContainer,
  ResultListItem,
  ResultsList,
  SearchVendorsContainer,
  SearchVendorsInputWrapper,
  SearchVendorsResultsContainer
} from './searchCustomVendors.styles'
import ThemeButton from '../../../../components/buttons/primary/ThemeButton'
import { SearchIcon, SSMButtonAddIcon } from '../../../../components/svg'
import { IntegrationLogo, NoIntegrationLogo } from '../../securityStackMap.styles'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  nist: SSMNistPillar
  asset: SSMAssetType
  handleAdd: (vendorId: string, nist: SSMNistPillar, asset: SSMAssetType) => void
  isAddingCustom: boolean
  customVendorOptions: Vendor[]
  setCustomVendorOptions: Dispatch<SetStateAction<Vendor[]>>
  drawerProps: DrawerProps
  value: string
  setValue: Dispatch<SetStateAction<string>>
  setVendorId: Dispatch<SetStateAction<string>>
}

const SearchCustomVendors: FC<Props> = ({
  customVendorOptions,
  setCustomVendorOptions,
  nist,
  asset,
  handleAdd,
  isAddingCustom,
  drawerProps,
  value,
  setValue,
  setVendorId
}) => {
  // const [customVendorOptions, setCustomVendorOptions] = useState<Vendor[]>([])
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<Vendor[]>(customVendorOptions)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSubmitting(true)
    setValue(e.target.value)
  }

  const handleSearchVendors = async (newValue?: string | undefined): Promise<void> => {
    setLoading(true)
    const val = newValue ?? value
    setValue(val)

    if (!val) {
      setValue('')
      setCustomVendorOptions([])
      setLoading(false)

      return
    }
    if (val.length < 2) return

    const newVal = val.includes('https://') ? val.replace('https://', '') : val
    try {
      const res = await axios.get<{ vendor: Vendor }>(`/api/v3/vendors_products/ext/search?domain=${newVal}`)

      const vendorId = res.data.vendor.uuid
      const drawerItemIds = drawerProps.assets.map((n) => n.uuid).filter((n) => typeof n === 'string')

      if (res.status === 200 && !drawerItemIds.includes(vendorId)) {
        const foundVendor = res.data.vendor
        setCustomVendorOptions([foundVendor])
        setOptions([foundVendor])
      }
    } catch (e) {
      console.error('Failed searching vendors', e)
      setCustomVendorOptions([])
      setOptions([])
    } finally {
      setLoading(false)
      setSubmitting(false)
    }
  }

  const debouncedSearch = useMemo(() => debounce(handleSearchVendors, 200), [value])

  return (
    <SearchVendorsContainer sx={{ marginTop: '0 !important' }}>
      <AutocompleteContainer>
        <SearchVendorsInputWrapper>
          <Box className="form-label-wrapper">
            <Typography className="form-label-text">Website URL</Typography>
          </Box>
          <TextField
            variant="outlined"
            color="primary"
            size="medium"
            placeholder="Search for domain..."
            value={value}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              autoComplete: 'off',
              onKeyDown: (e) => {
                if (e.keyCode === 13) {
                  const newValue = (e as any).target.value || ''
                  setValue(newValue)
                  debouncedSearch(newValue)
                }
              },
              endAdornment: value.length > 0 && (
                <IconButton
                  onClick={() => {
                    setOptions([])
                    setCustomVendorOptions([])
                    setValue('')
                    setVendorId('')
                  }}
                  className="end-adornment-close-icon"
                >
                  <CloseIcon width={24} />
                </IconButton>
              )
            }}
          />
        </SearchVendorsInputWrapper>
        {/* Loading */}
        {loading && (
          <SearchVendorsResultsContainer>
            <Box className="search-vendors-loading-wrapper">
              <CircularProgress size={48} className="search-vendors-loading-progress" />
            </Box>
          </SearchVendorsResultsContainer>
        )}
        {/* No results found */}
        {value.length > 0 && !options.length && !loading && !submitting && (
          <SearchVendorsResultsContainer>
            <Box className="search-vendors-empty-wrapper">
              <Typography className="search-vendors-empty-title">Vendor Not Found :(</Typography>
              <Typography className="search-vendors-empty-text">
                Make sure your spelling is correct, and enter the address without 'http://' or a trailing '/'
              </Typography>
            </Box>
          </SearchVendorsResultsContainer>
        )}
        {/* Results */}
        {(options.length === 1 &&
          (options as Vendor[])[0].display_name === '' &&
          (options as Vendor[])[0].name === '') ||
          (options.length > 0 && !loading && (
            <SearchVendorsResultsContainer>
              <Typography className="search-vendors-results-text">Top Matches</Typography>
              <ResultsList length={options.length}>
                {(options as Vendor[]).map((option, index) => {
                  if (option.display_name === '' && option.name === '') return null

                  return (
                    <ResultListItem iscustomvendor="true" key={index}>
                      {option.logo_path && option.logo_path.length > 0 ? (
                        <IntegrationLogo
                          logo={`${process.env.REACT_APP_LOGO_HOST}/${option.logo_path}`}
                          sx={{
                            width: '24px !important',
                            height: '24px !important',
                            marginRight: `${theme.spacing.small}px`
                          }}
                        />
                      ) : (
                        <NoIntegrationLogo
                          sx={{
                            width: '24px !important',
                            height: '24px !important',
                            marginRight: `${theme.spacing.small}px`
                          }}
                        >
                          <Typography className="no-integration-logo-letter">{option.display_name[0]}</Typography>
                        </NoIntegrationLogo>
                      )}
                      <Typography className="option-text">{option.display_name}</Typography>
                      {isAddingCustom ? (
                        <CircularProgress className="add-custom-loading-wrapper" size={24} />
                      ) : (
                        <ThemeButton
                          themevariant="tertiary"
                          sx={{ padding: `${theme.spacing.sm}px ${theme.spacing.md}px !important`, minWidth: '100px' }}
                          onClick={() => {
                            handleAdd(option.uuid, nist, asset)
                            setOptions([])
                            setValue(option.display_name)
                            setSubmitting(true)
                          }}
                          disabled={isAddingCustom}
                        >
                          <>
                            <SSMButtonAddIcon />
                            Add
                          </>
                        </ThemeButton>
                      )}
                    </ResultListItem>
                  )
                })}
              </ResultsList>
            </SearchVendorsResultsContainer>
          ))}
      </AutocompleteContainer>
      <ThemeButton
        themevariant="secondary"
        onClick={() => debouncedSearch()}
        sx={{
          minWidth: 'unset',
          width: 'fit-content !important',
          padding: `${theme.spacing.xSmall}px 15.5px !important`,

          svg: {
            width: '24px !important',
            height: '24px !important'
          }
        }}
      >
        <>
          <SearchIcon />
          Search
        </>
      </ThemeButton>
    </SearchVendorsContainer>
  )
}

export default SearchCustomVendors
