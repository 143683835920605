import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import { DataStatus } from '../../../../../../models'
import theme from 'theme'

export const ChartWrapper = styled(Box)<{ status?: DataStatus; severity: 'healthy' | 'problematic'; zone: string }>`
  display: flex;
  flex-direction: column;
  //margin-top: 17px;
  padding: ${theme.spacing.medium}px;
  max-width: 1686px;
  margin-left: auto;
  margin-right: auto;

  .chart-container {
    width: 100%;
    height: 499px;
    margin-right: 41px;
    max-width: 1724px;
    background: ${theme.colors.backgroundLight};
    box-shadow: ${theme.attributes.boxShadow.feedSection};
    border-bottom-right-radius: ${theme.attributes.borderRadius.quaternary};
    border-bottom-left-radius: ${theme.attributes.borderRadius.quaternary};

    @media (min-height: 801px) {
      height: 500px;
    }

    @media (min-height: 901px) {
      height: 568px;
    }

    .trends-d3-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      background: ${theme.colors.primaryDark};
      border-bottom-right-radius: ${theme.attributes.borderRadius.quaternary};
      border-bottom-left-radius: ${theme.attributes.borderRadius.quaternary};

      .svg-d3 {
        border-bottom-right-radius: ${theme.attributes.borderRadius.quaternary};
        border-bottom-left-radius: ${theme.attributes.borderRadius.quaternary};
        border: 1px solid rgba(147, 143, 153, 0.16);
        background: ${theme.colors.backgroundLight};
        box-shadow: ${theme.attributes.boxShadow.feedSection};

        .event-indicator-count {
          cursor: pointer;
        }

        .add-event-button {
          cursor: pointer;
          display: none;

          &:hover {
            .add-button-circle {
              fill: #4e414d;
              stroke: ${theme.baseColors.greyscale[80]};
            }
          }
        }

        .add-button-tooltip {
          display: none;
        }

        .count-tooltip {
          display: none;
        }

        .sla {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: ${theme.spacing.small}px;
          background: rgba(208, 188, 255, 0.16);
          box-shadow: ${theme.attributes.boxShadow.selectFieldPaper};
          border: 0 solid ${theme.colors.controlBorder};
          border-radius: ${theme.attributes.borderRadius.quaternary};
          backdrop-filter: blur(6px) brightness(0.8) contrast(0.9) hue-rotate(320deg);
          z-index: 1000000;

          .sla-tooltip-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: ${theme.spacing.small}px;
            background: rgba(208, 188, 255, 0.16);
            box-shadow: ${theme.attributes.boxShadow.selectFieldPaper};
            border: 0 solid ${theme.colors.controlBorder};
            border-radius: ${theme.attributes.borderRadius.quaternary};
            backdrop-filter: blur(6px) brightness(0.8) contrast(0.9) hue-rotate(320deg);
            z-index: 1000000;
          }
        }
      }

      .data-point-tooltip {
        display: none;
        position: absolute;
        opacity: 1;
        padding: ${theme.spacing.small}px;
        gap: ${theme.spacing.sm}px;
        border-radius: ${theme.attributes.borderRadius.secondary};
        border: 1px solid ${theme.colors.controlBorder};
        background: ${theme.colors.borderLow};
        box-shadow: ${theme.attributes.boxShadow.chipCircle};
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 24px;
        letter-spacing: 0.1px;
        z-index: 10000;

        .prediction-text-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 232px;
          gap: ${theme.spacing.xSmall}px;
          margin-top: -${theme.spacing.half}px;

          .tooltip-performance {
            color: ${theme.colors.white};
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.xLarge};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.bold};
            line-height: 24px;
            letter-spacing: 0.1px;
          }

          svg {
            margin-left: -${theme.spacing.xsm}px;
          }
        }

        .tooltip-units-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 232px;

          .tooltip-units-prediction {
            color: ${theme.colors.white};
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.large};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.normal};
            line-height: 20px;
            letter-spacing: 0.25px;
            margin-top: 0;
            margin-bottom: 0;
          }
        }

        .tooltip-performance,
        .tooltip-units {
          color: ${theme.colors.white};
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.large};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: 20px;
          letter-spacing: 0.25px;
        }

        .tooltip-performance {
          margin: ${theme.spacing.xSmall}px 0;

          &.prediction-date {
            color: ${theme.colors.white};
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.xLarge};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.bold};
            line-height: 24px;
            letter-spacing: 0.1px;
            margin-top: 2px;
          }
        }

        .tooltip-units {
          margin: 0;
        }

        .severity-text {
          text-transform: capitalize;
        }
      }
    }
  }

  .chart-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    background: #2c242c;
  }

  .chart-container::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #524256;
  }

  .chart-container::-webkit-scrollbar-thumb:hover {
    background-color: #524256;
  }

  .chart-container::-webkit-scrollbar:vertical {
    display: none;
  }
`

export const DescriptionWrapper = styled(Box)<{
  status?: DataStatus
  zone: string
}>`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.sm}px;
  border-radius: ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary} 0 0;
  background: ${theme.colors.primaryDarker};
  border: 1px solid rgba(147, 143, 153, 0.16);
  box-shadow: ${theme.attributes.boxShadow.feedSection};
  height: 150px;

  @media (min-height: 801px) {
    gap: ${theme.spacing.sm}px;
  }

  @media (max-width: 1440px) and (max-height: 900px) {
    gap: ${theme.spacing.sm}px;

    .chips-container {
      margin-top: 0;
    }
  }

  @media (max-width: 1440px) and (min-height: 901px) {
    gap: ${theme.spacing.sm}px;

    .chips-container {
      margin-top: 0;
    }
  }

  @media (max-width: 1366px) and (max-height: 768px) {
    gap: ${theme.spacing.sm}px;

    .chips-container {
      margin-top: 0;
    }
  }

  @media (max-width: 1280px) {
    gap: ${theme.spacing.sm}px;

    .chips-container {
      margin-top: 0;
    }
  }

  @media (max-height: 800px) {
    gap: ${theme.spacing.sm}px;
    height: 119px;
  }

  .box-wrapper {
    border-radius: ${theme.attributes.borderRadius.tertiary};
    background: #281e28;
    padding: ${theme.spacing.small}px;

    .measurement-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      gap: ${theme.spacing.small}px;
      padding: 0 ${theme.spacing.small}px;

      .status {
        display: flex;
        align-items: flex-end;

        span {
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.medium};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: 16px;
          letter-spacing: 0.4px;
        }

        @media (max-width: 1440px) {
          font-size: 2.3rem !important;
          line-height: normal !important;
        }
      }

      .column {
        flex-direction: column;
      }
    }

    .box-wrapper-options {
      display: flex;
      gap: ${theme.spacing.md}px;

      @media (max-width: 1400px) {
        flex-wrap: wrap;
      }
    }

    .label-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      text-transform: none;

      > div {
        cursor: pointer;
      }

      .title {
        margin-bottom: ${theme.spacing.xSmall}px;
      }

      .count {
        border-radius: ${theme.attributes.borderRadius.large};
        border: 1px solid ${theme.colors.controlBorder};
        background: ${theme.baseColors.info[30]};
        position: absolute;
        top: 30px;
        right: 5px;
        min-width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #66006e;
        text-align: center;
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.mediumSmall};
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.5px;
      }
    }
  }

  .wrapper-options {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 679px;
    padding-left: ${theme.spacing.small}px;
    padding-right: ${theme.spacing.small}px;
    padding-bottom: ${theme.spacing.small}px;
    min-width: 529px;
  }

  .wrapper-options,
  .legend-wrapper,
  .wrapper-measurement {
    width: 100%;
  }

  .wrapper-measurement {
    @media (max-width: 1280px) {
      max-width: 266px;
    }

    @media (max-width: 1440px) {
      max-width: 312px;
    }

    @media (min-width: 1441px) {
      max-width: 422px;
    }
  }

  .px-7 {
    padding-left: 7px;
    padding-right: 7px;
  }

  .legend-wrapper {
    padding: ${theme.spacing.small}px;
    max-width: 363px;

    @media (max-width: 1400px) {
      max-width: 187px;
      width: auto;
    }

    @media (max-width: 1280px) {
      max-width: 187px;
    }

    @media (max-height: 800px) {
      padding-top: ${theme.spacing.sm}px;
      padding-bottom: unset;

      .legend-key {
        margin-right: 13px;
      }

      .legend-container {
        gap: 3px !important;
      }
    }

    .legend {
      @media (max-width: 1400px) {
        display: flex;
        flex-direction: row;
        flex-flow: nowrap;
        gap: ${theme.spacing.md}px;
        align-items: start;
        justify-content: center;
      }
    }

    @media screen and (max-width: 1440px) and (max-height: 800px) {
      max-width: 279px;

      .legend {
        flex-flow: column wrap;

        .legend-container {
          flex-flow: row wrap;
          gap: ${theme.spacing.xSmall}px ${theme.spacing.small}px !important;
        }
      }
    }
  }

  .text {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.large};
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${theme.colors.white};

    .severity {
      text-transform: capitalize;
    }
  }

  .status {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: 76.533px;
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 57.439px;
    color: ${theme.baseColors.info[20]};
    margin-top: 0;
    text-transform: capitalize;

    ${({ status, zone }) =>
      status &&
      status === DataStatus.Ready &&
      zone === 'green' &&
      `
        color: ${theme.baseColors.info[20]};
      `};

    ${({ status, zone }) =>
      status &&
      status === DataStatus.Ready &&
      zone === 'red' &&
      `
        color: ${theme.baseColors.info[50]};
      `};

    ${({ status }) =>
      status &&
      status === DataStatus.Initializing &&
      `
        color: #FAC364;
      `};

    ${({ status }) =>
      status &&
      status === DataStatus.Recalculating &&
      `
        font-size: 58px;
        line-height: normal;
        color: #FAC364;
      `};
  }

  .updated-at {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.mediumSmall};
    line-height: 16px;
    letter-spacing: 0.5px;
    color: ${theme.baseColors.greyscale[80]};
  }

  .legend {
    width: auto;
    display: flex;
    flex-flow: column wrap;

    .legend-container {
      display: flex;
      flex-wrap: wrap;
      gap: 14px;

      @media (max-width: 1500px) {
        gap: ${theme.spacing.xSmall}px;
      }
    }

    .legend-key {
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      letter-spacing: 0.1px;
      color: ${theme.colors.white};
      margin-bottom: ${theme.spacing.xSmall}px;
    }

    .legend-item {
      display: flex;
      align-items: center;

      .MuiTypography-root {
        margin-left: ${theme.spacing.xSmall}px;
        font-family: ${theme.typography.fontFamily.primary};
        font-weight: ${theme.typography.fontWeight.bold};
        font-size: ${theme.typography.fontSize.medium};
        line-height: 16px;
        letter-spacing: 0.5px;
        color: ${theme.colors.white};
        white-space: nowrap;
      }
    }
  }

  .data-source-wrapper {
    display: flex;
    flex-flow: column wrap;

    .data-source-select-wrapper {
      max-width: 180px;
      margin-top: ${theme.spacing.half}px;
    }

    .title {
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      letter-spacing: 0.1px;
      color: ${theme.colors.white}fff;
      margin-bottom: ${theme.spacing.half}px;
    }
  }
`

export const LabelsMenuWrapper = styled(Box)`
  border-radius: ${theme.attributes.borderRadius.tertiary};
  background: #281e28;
  padding: ${theme.spacing.small}px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 128px;
  width: 100%;

  @media (min-width: 1920px) {
    padding-top: 0 !important;
  }

  @media (max-width: 1400px) {
    max-width: none;
    width: auto;
  }

  @media (max-height: 800px) {
    padding: ${theme.spacing.small}px 5px 0 5px;
    max-width: 60px;
  }

  .measurement-wrapper {
    width: 100%;
    min-width: 350px;
    display: flex;
    align-items: center;
    gap: ${theme.spacing.small}px;
  }

  .box-wrapper-options {
    display: flex;
    gap: ${theme.spacing.md}px;

    @media (max-width: 1400px) {
      flex-wrap: wrap;
    }
  }

  .label-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-transform: none;

    > div {
      cursor: pointer;
    }

    .title {
      margin-bottom: 7px;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
    }

    .count {
      border-radius: ${theme.attributes.borderRadius.large};
      border: 1px solid ${theme.colors.controlBorder};
      background: ${theme.baseColors.info[30]};
      position: absolute;
      top: 30px;
      right: 5px;
      min-width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #66006e;
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.mediumSmall};
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }
`

export const StatusValueText = styled(Typography)<{ status?: DataStatus; zone: string }>`
  font-family: ${theme.typography.fontFamily.primary};
  font-size: 76.533px;
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.normal};
  line-height: 57.439px;
  color: ${theme.baseColors.info[20]};
  margin-top: 0;
  text-transform: capitalize;

  ${({ status }) =>
    (status && status === DataStatus.Initializing) ||
    (status === DataStatus.Recalculating &&
      `
        color: #FAC364;
      `)};

  ${({ status, zone }) =>
    status &&
    status === DataStatus.Ready &&
    zone === 'green' &&
    `
        color: ${theme.baseColors.info[20]};
      `};

  ${({ status, zone }) =>
    status &&
    status === DataStatus.Ready &&
    zone === 'red' &&
    `
        color: ${theme.baseColors.info[50]};
      `};

  ${({ status }) =>
    status &&
    status === DataStatus.Initializing &&
    `
        color: #FAC364;
      `};
`

export const OnyxAIWrapper = styled(Box)<{ has_model: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${theme.attributes.borderRadius.tertiary};
  background: #281e28;
  padding: ${theme.spacing.md}px ${theme.spacing.small}px;
  width: 100%;
  max-width: 80px;

  .onyxai-text {
    color: ${theme.colors.white};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-top: ${theme.spacing.xSmall}px;
    margin-bottom: 2px;

    ${({ has_model: hasModel }) =>
      hasModel === 'false' &&
      `
      margin-top: 0;
    `};
  }

  .onyxai-btn {
    margin-top: -2px;
    text-transform: none;
    padding: 0;
  }

  .not-available-svg {
    margin-top: ${theme.spacing.half}px;

    &:hover {
      circle {
        fill-opacity: 0.12;
        stroke-opacity: 0.4;
      }
    }
  }
`

export const OnyxAITooltipWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${theme.spacing.xSmall}px;

  .tooltip-title {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .tooltip-text {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`

export const PredictionDescriptionWrapper = styled(Box)<{ status?: DataStatus; zone: string }>`
  border-radius: ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary} 0 0;
  background: ${theme.colors.primaryDarker};
  padding: 18px;
  gap: 18px;
  width: 100%;
  height: 119px;
  display: flex;
  align-items: center;

  @media (min-width: 1920px) {
    height: 150px;
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1px;
    border-radius: ${theme.attributes.borderRadius.tertiary};
    background: #281e28;
    width: 217px;
    height: 99px;
    padding: ${theme.spacing.md}px ${theme.spacing.small}px ${theme.spacing.md}px ${theme.spacing.md}px;

    @media (min-width: 1440px) {
      width: 262px;
      padding: ${theme.spacing.md}px 37px;
    }

    @media (min-width: 1920px) {
      width: 270px;
      height: 114px;
      padding: 19px ${theme.spacing.xLarge}px;
    }

    .recent-performance-value {
      color: ${theme.baseColors.info[20]};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.huge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      margin-bottom: 5px;

      span {
        color: ${theme.baseColors.info[20]};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.header};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
      }

      ${({ status, zone }) =>
        status &&
        zone === 'green' &&
        `
        color: ${theme.baseColors.info[20]};
        
        span {
          color: ${theme.baseColors.info[20]};
        }
      `};

      ${({ status, zone }) =>
        status &&
        zone === 'red' &&
        `
        color: ${theme.baseColors.info[50]} !important;
        
        span {
          color: ${theme.baseColors.info[50]} !important;
        }
      `};
    }

    .predicted-performance-value {
      color: ${theme.baseColors.info[20]};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.huge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      margin-bottom: 5px;

      span {
        color: ${theme.baseColors.info[20]};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.header};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
      }
    }

    .recent-performance-text {
      color: ${theme.baseColors.info[20]};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;

      ${({ status, zone }) =>
        status &&
        zone === 'green' &&
        `
        color: ${theme.baseColors.info[20]};
      `};

      ${({ status, zone }) =>
        status &&
        zone === 'red' &&
        `
        color: ${theme.baseColors.info[50]};
      `};
    }

    .updated-at {
      color: ${theme.baseColors.greyscale[80]};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.medium};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 16px;
      letter-spacing: 0.4px;
      text-transform: capitalize;
    }

    &.section-prediction {
      width: 220px;
      padding: ${theme.spacing.md}px ${theme.spacing.small}px ${theme.spacing.md}px ${theme.spacing.md}px;

      .predicted-performance-value {
        color: #bb95ea;

        span {
          color: #bb95ea;
        }
      }

      .recent-performance-text {
        color: #bb95ea;
      }

      @media (min-width: 1440px) {
        width: 265px;
        padding: ${theme.spacing.md}px 37px;
      }

      @media (min-width: 1920px) {
        width: 267px;
        padding: 19px ${theme.spacing.xLarge}px;
      }
    }

    &.section-legend {
      width: 198px;
      padding: 10.8px ${theme.spacing.small}px;

      @media (min-width: 1440px) {
        width: 232px;
        padding: 10.8px 33px;
      }

      @media (min-width: 1920px) {
        width: 393px;
        padding: 19px 29px 20.4px;
      }

      .legend-title {
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.large};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 20px;
        letter-spacing: 0.1px;
        margin-bottom: ${theme.spacing.sm}px;
      }

      .legend-wrapper {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        flex-basis: 100%;
        row-gap: ${theme.spacing.half}px;

        @media (min-width: 1920px) {
          row-gap: ${theme.spacing.sm}px;
        }

        .legend-item {
          display: flex;
          align-items: center;
          flex-basis: 50%;
          gap: ${theme.spacing.xSmall}px;

          .MuiTypography-root {
            color: ${theme.colors.white};
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.medium};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.bold};
            line-height: 16px;
            letter-spacing: 0.5px;
            white-space: nowrap;
          }
        }
      }
    }

    &.section-controls {
      flex-direction: row;
      gap: 36px;
      justify-content: flex-start;
      width: 459px;
      padding: ${theme.spacing.sm}px 0 21px 17px;

      @media (min-width: 1440px) {
        width: 477px;
        flex-grow: 1;
        padding: ${theme.spacing.sm}px 0 21px 26.5px;
      }

      @media (min-width: 1920px) {
        width: 666px;
        padding: 19px 0 19px 36px;
      }

      .data-source-wrapper {
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing.small}px;

        .title {
          color: ${theme.colors.white};
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.large};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.bold};
          line-height: 20px;
          letter-spacing: 0.1px;
        }
      }
    }
  }
`
