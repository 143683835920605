/* eslint camelcase: 0 */
import { Box, FormControl as MuiFormControl, Select as MuiSelect } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 0

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column wrap;
  width: 100%;
  gap: ${theme.spacing.xSmall}px;

  .form-control-label {
    width: 100%;
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
  }
`

export const FormControl = styled(MuiFormControl)<{ isselected?: string; is_opened: string; is_disabled: string }>`
  border: 1px solid ${theme.colors.controlBorder};
  background: transparent;
  outline: none;
  min-width: 113px;
  height: 40px;
  border-radius: ${theme.attributes.borderRadius.small};
  width: 100%;

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    color: ${theme.colors.white};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  &:hover {
    border-radius: ${theme.attributes.borderRadius.small};
    background: rgba(234, 221, 255, 0.08);

    .MuiOutlinedInput-root {
      color: ${theme.colors.textPrimary};
    }
  }

  svg {
    top: calc(50% - 0.6em);
    right: ${theme.spacing.small}px;

    path {
      fill: ${theme.colors.textPrimary};
    }
  }

  ${({ is_disabled }) =>
    is_disabled &&
    is_disabled === 'true' &&
    `
      opacity: 0.38;
      pointer-events: none;
      cursor: default;
      border-color: ${theme.colors.white};
      
      &:hover {
        background: transparent;
    
        .MuiOutlinedInput-root {
          color: ${theme.colors.textPrimary};
        }
      }
      
       .MuiOutlinedInput-root {
        color: ${theme.colors.textPrimary};
      }
  `};

  ${({ is_opened }) =>
    is_opened &&
    is_opened === 'true' &&
    `
      border: 1px solid ${theme.colors.textPrimary};
      background: rgba(208, 188, 255, 0.16);
      
      .MuiOutlinedInput-root {
        color: ${theme.colors.textPrimary};
      }
  `};

  ${({ isselected }) =>
    isselected &&
    isselected === 'true' &&
    `
    background: rgba(234, 221, 255, 0.08);
    color: ${theme.colors.textPrimary} !important;
    border: 1px solid ${theme.colors.textPrimary} !important;
    
    .MuiOutlinedInput-root {
      border: 1px solid ${theme.colors.textPrimary} !important;
    }
  `};
`

export const Select = styled(MuiSelect)<{ isselected?: string; noborder?: string }>`
  min-width: 113px;
  height: 40px;
  background: transparent;
  border-radius: ${theme.attributes.borderRadius.small};
  border: 1px solid ${theme.colors.controlBorder};
  gap: ${theme.spacing.xSmall}px;

  ${({ noborder }) =>
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};

  color: ${theme.baseColors.greyscale[80]};
  text-align: center;
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.large};
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: capitalize;

  ${({ isselected }) =>
    isselected === 'true' &&
    `
      background: rgba(234, 221, 255, 0.08);
      color: ${theme.colors.textPrimary} !important;
    
      .MuiOutlinedInput-root {
        .MuiSelect-select {
          color: ${theme.colors.textPrimary} !important;
        }
      }    
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .MuiOutlinedInput-input {
    text-align: left;
  }

  svg {
    color: ${theme.baseColors.greyscale[80]};
  }
`

export const MenuProps = {
  PaperProps: {
    sx: {
      background: theme.colors.primaryLight,
      boxShadow: theme.attributes.boxShadow.chipCircle,
      borderRadius: theme.attributes.borderRadius.secondary,
      minWidth: '200px',
      // width: '200px',

      '.MuiMenuItem-root': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minWidth: '200px',
        height: '48px',
        paddingLeft: `${theme.spacing.small}px`,
        paddingRight: `${theme.spacing.small}px`,

        img: {
          marginRight: `${theme.spacing.xSmall}px`
        },

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.08)'
        },

        '.MuiCheckbox-root': {
          marginRight: `${theme.spacing.xSmall}px`,

          '&.Mui-checked': {
            svg: {
              path: {
                fill: `${theme.colors.textPrimary}`
              }
            }
          },

          '&:hover': {
            borderRadius: theme.attributes.borderRadius.large,
            background: 'rgba(202, 196, 208, 0.12)'
          },

          svg: {
            width: '18px',
            height: '18px',

            path: {
              fill: theme.baseColors.greyscale[80]
            }
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: theme.typography.fontFamily.primary,
          fontWeight: theme.typography.fontWeight.normal,
          fontSize: theme.typography.fontSize.xLarge,
          lineHeight: '24px',
          letterSpacing: '0.5px',
          color: theme.baseColors.greyscale[80],
          fontStyle: 'normal',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }
      },

      '.Mui-selected': {
        backgroundColor: 'rgba(208, 188, 255, 0.16) !important'
      },

      '&::-webkit-scrollbar': {
        width: '4px',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-track': {
        boxShadow: 'none',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#FBD8F5',
        borderRadius: theme.attributes.borderRadius.mediumSmall
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}
