export enum DEVICES_COLUMN_NAME {
  UDID = 'UDID',
  ONYXIA_PRODUCT_NAME = 'ONYXIA_PRODUCT_NAME',
  ONYXIA_PRODUCT_INSTANCE = 'ONYXIA_PRODUCT_INSTANCE',
  ONYXIA_TENANT_SLUG = 'ONYXIA_TENANT_SLUG',
  HOSTNAME = 'HOSTNAME',
  SERIAL_NUMBER = 'SERIAL_NUMBER',
  ASSET_TYPE = 'ASSET_TYPE',
  USER_ID = 'USER_ID',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  USER_NAME = 'USER_NAME',
  GROUP_NAME = 'GROUP_NAME',
  GROUP_IDS = 'GROUP_IDS',
  FIRST_SEEN = 'FIRST_SEEN',
  LAST_SEEN = 'LAST_SEEN',
  MAC_ADDRESSES = 'MAC_ADDRESSES',
  IP_ADDRESSES = 'IP_ADDRESSES',
  OS_NAME = 'OS_NAME',
  OS_VERSION = 'OS_VERSION',
  ENCRYPTION_STATUS = 'ENCRYPTION_STATUS',
  MANAGEMENT_STATUS = 'MANAGEMENT_STATUS',
  COMPLIANCE_STATUS = 'COMPLIANCE_STATUS',
  SUPERVISION_STATUS = 'SUPERVISION_STATUS',
  ENTITY_URL = 'ENTITY_URL',
  APPLE_MANAGEMENT_STATUS = 'APPLE_MANAGEMENT_STATUS'
}

export type DevicesColumns = Array<{ name: DEVICES_COLUMN_NAME; type: string | string[] | boolean; active?: boolean }>

export interface DevicesManifest {
  slug: 'device'
  spec: {
    columns: DevicesColumns
  }
  supported_products: Array<string>
  id: string
  updated_at: string
  description: string
  title: string
  druid_spec: {
    data_schema: {
      dimensions_spec: {
        dimensions: Array<string | { type: string; name: string }>
        // [
        //   'UDID',
        //   'ONYXIA_PRODUCT_NAME',
        //   'ONYXIA_PRODUCT_INSTANCE',
        //   'ONYXIA_TENANT_SLUG',
        //   'HOSTNAME',
        //   'SERIAL_NUMBER',
        //   'ASSET_TYPE',
        //   'USER_ID',
        //   'EMAIL_ADDRESS',
        //   'USER_NAME',
        //   'GROUP_NAME',
        //   {
        //     type: 'string'
        //     name: 'GROUP_IDS'
        //   },
        //   {
        //     type: 'long'
        //     name: 'FIRST_SEEN'
        //   },
        //   {
        //     type: 'long'
        //     name: 'LAST_SEEN'
        //   },
        //   {
        //     type: 'string'
        //     name: 'MAC_ADDRESSES'
        //   },
        //   {
        //     type: 'string'
        //     name: 'IP_ADDRESSES'
        //   },
        //   'OS_NAME',
        //   'OS_VERSION',
        //   'ENCRYPTION_STATUS',
        //   'MANAGEMENT_STATUS',
        //   'COMPLIANCE_STATUS',
        //   'SUPERVISION_STATUS',
        //   'ENTITY_URL'
        // ]
      }
    }
    transform_spec: {
      transforms: [
        {
          type: string
          name: string
          expression: string
        },
        {
          type: string
          name: string
          expression: string
        },
        {
          type: string
          name: string
          expression: string
        },
        {
          type: string
          name: string
          expression: string
        },
        {
          type: string
          name: string
          expression: string
        }
      ]
    }
  }
  supported_metrics: Array<string>
  created_at: string
}

export interface DevicesDataItem {
  [DEVICES_COLUMN_NAME.ASSET_TYPE]: string | null
  [DEVICES_COLUMN_NAME.EMAIL_ADDRESS]: string | null
  [DEVICES_COLUMN_NAME.ENTITY_URL]: string | null
  [DEVICES_COLUMN_NAME.FIRST_SEEN]: string | null
  [DEVICES_COLUMN_NAME.GROUP_IDS]: string | null
  [DEVICES_COLUMN_NAME.GROUP_NAME]: string | null
  [DEVICES_COLUMN_NAME.HOSTNAME]: string | null
  [DEVICES_COLUMN_NAME.IP_ADDRESSES]: string | null
  [DEVICES_COLUMN_NAME.LAST_SEEN]: string | null
  [DEVICES_COLUMN_NAME.MAC_ADDRESSES]: string | null
  [DEVICES_COLUMN_NAME.ONYXIA_PRODUCT_INSTANCE]: string | null
  [DEVICES_COLUMN_NAME.ONYXIA_PRODUCT_NAME]: string | null
  [DEVICES_COLUMN_NAME.ONYXIA_TENANT_SLUG]: string | null
  [DEVICES_COLUMN_NAME.OS_NAME]: string | null
  [DEVICES_COLUMN_NAME.OS_VERSION]: string | null
  [DEVICES_COLUMN_NAME.SERIAL_NUMBER]: string | null
  [DEVICES_COLUMN_NAME.UDID]: string | null
  [DEVICES_COLUMN_NAME.USER_ID]: string | null
  [DEVICES_COLUMN_NAME.USER_NAME]: string | null
  [DEVICES_COLUMN_NAME.COMPLIANCE_STATUS]: boolean | null
  [DEVICES_COLUMN_NAME.ENCRYPTION_STATUS]: boolean | null
  [DEVICES_COLUMN_NAME.MANAGEMENT_STATUS]: boolean | null
  [DEVICES_COLUMN_NAME.SUPERVISION_STATUS]: boolean | null
  [DEVICES_COLUMN_NAME.APPLE_MANAGEMENT_STATUS]: boolean | null
  __time: string
}
