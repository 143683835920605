import { FC, Dispatch, SetStateAction } from 'react'
import { IconButton as MuiIconButton, CircularProgress } from '@mui/material'
import theme from 'theme'
import styled from '@emotion/styled/macro'

const IconButton = styled(MuiIconButton)`
  border-radius: ${theme.attributes.borderRadius.tertiary};
  border: 1px solid ${theme.colors.borderMedium};
  display: flex;
  width: 48px;
  height: 48px;
  padding: ${theme.spacing.xSmall}px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing.sm}px;

  &:hover {
    background: #393038;
  }
`

interface Props {
  type: string
  setAnchorEl?: Dispatch<SetStateAction<HTMLElement | null>>
  handleClick?: () => void
  loading?: boolean
}

const ControlButton: FC<Props> = ({ type, setAnchorEl, handleClick, loading }) => (
  <IconButton
    ref={(elm) => {
      if (setAnchorEl) {
        setAnchorEl(elm)
      }
    }}
    onClick={handleClick}
    aria-describedby="placement-popper"
    disabled={loading}
  >
    {loading ? (
      <CircularProgress size="16px" sx={{ color: theme.colors.textPrimary }} />
    ) : (
      <>
        {type === 'settings' && (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.99473 3.28344C8.07007 2.83139 8.46119 2.50006 8.91947 2.50006H11.0811C11.5394 2.50006 11.9305 2.83139 12.0059 3.28344L12.1838 4.35098C12.2357 4.66268 12.444 4.92232 12.7205 5.07536C12.7823 5.10958 12.8434 5.14492 12.9038 5.18137C13.1746 5.34493 13.5041 5.39578 13.8004 5.28478L14.8144 4.90487C15.2436 4.7441 15.7261 4.91715 15.9552 5.31404L17.0361 7.18607C17.2652 7.58296 17.1738 8.08734 16.82 8.37861L15.9831 9.06762C15.7393 9.26829 15.6185 9.57816 15.6243 9.89383C15.625 9.92916 15.6253 9.96457 15.6253 10.0001C15.6253 10.0355 15.625 10.0709 15.6243 10.1063C15.6185 10.422 15.7393 10.7318 15.9831 10.9325L16.82 11.6215C17.1738 11.9128 17.2652 12.4172 17.0361 12.814L15.9552 14.6861C15.7261 15.083 15.2436 15.256 14.8144 15.0952L13.8004 14.7153C13.5041 14.6043 13.1747 14.6552 12.9038 14.8187C12.8434 14.8552 12.7823 14.8905 12.7205 14.9248C12.444 15.0778 12.2357 15.3374 12.1838 15.6491L12.0059 16.7167C11.9305 17.1687 11.5394 17.5001 11.0811 17.5001H8.91947C8.46119 17.5001 8.07007 17.1687 7.99473 16.7167L7.8168 15.6491C7.76485 15.3374 7.55654 15.0778 7.28007 14.9248C7.21826 14.8905 7.15716 14.8552 7.0968 14.8188C6.82594 14.6552 6.49649 14.6043 6.20019 14.7153L5.18613 15.0952C4.75698 15.256 4.27448 15.083 4.04534 14.6861L2.96452 12.8141C2.73538 12.4172 2.82676 11.9128 3.18057 11.6215L4.01753 10.9325C4.26129 10.7318 4.38212 10.422 4.37627 10.1063C4.37562 10.071 4.37529 10.0355 4.37529 10.0001C4.37529 9.96458 4.37562 9.92917 4.37627 9.89385C4.38212 9.57817 4.26129 9.2683 4.01753 9.06764L3.18057 8.37862C2.82675 8.08735 2.73538 7.58297 2.96452 7.18608L4.04534 5.31405C4.27448 4.91716 4.75698 4.74411 5.18613 4.90489L6.20018 5.28478C6.49648 5.39579 6.82593 5.34494 7.09679 5.18138C7.15715 5.14493 7.21825 5.10958 7.28007 5.07536C7.55654 4.92232 7.76485 4.66268 7.8168 4.35098L7.99473 3.28344Z"
              stroke={theme.baseColors.greyscale[80]}
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.5001 9.99999C12.5001 11.3807 11.3808 12.5 10.0001 12.5C8.61939 12.5 7.5001 11.3807 7.5001 9.99999C7.5001 8.61928 8.61939 7.49999 10.0001 7.49999C11.3808 7.49999 12.5001 8.61928 12.5001 9.99999Z"
              stroke={theme.baseColors.greyscale[80]}
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
        {type === 'export-pdf' && (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.25 11.875V9.6875C16.25 8.1342 14.9908 6.875 13.4375 6.875H12.1875C11.6697 6.875 11.25 6.45527 11.25 5.9375V4.6875C11.25 3.1342 9.9908 1.875 8.4375 1.875H6.875M7.5 11.875L10 14.375M10 14.375L12.5 11.875M10 14.375L10 9.375M8.75 1.875H4.6875C4.16973 1.875 3.75 2.29473 3.75 2.8125V17.1875C3.75 17.7053 4.16973 18.125 4.6875 18.125H15.3125C15.8303 18.125 16.25 17.7053 16.25 17.1875V9.375C16.25 5.23286 12.8921 1.875 8.75 1.875Z"
              stroke="#D0C3CC"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
        {type === 'export' && (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.5 13.75V15.625C2.5 16.6605 3.33947 17.5 4.375 17.5H15.625C16.6605 17.5 17.5 16.6605 17.5 15.625V13.75M6.25 6.25L10 2.5M10 2.5L13.75 6.25M10 2.5L10 13.75"
              stroke={theme.baseColors.greyscale[80]}
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
        {type === 'columns' && (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.5 3.75V16.25M12.5 3.75V16.25M3.4375 16.25H16.5625C17.0803 16.25 17.5 15.8303 17.5 15.3125V4.6875C17.5 4.16973 17.0803 3.75 16.5625 3.75H3.4375C2.91973 3.75 2.5 4.16973 2.5 4.6875V15.3125C2.5 15.8303 2.91973 16.25 3.4375 16.25Z"
              stroke={theme.baseColors.greyscale[80]}
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
      </>
    )}
  </IconButton>
)

export default ControlButton
