import { FC } from 'react'
import { Typography, IconButton } from '@mui/material'
import theme from 'theme'
import dayjs from 'dayjs'

/* Utils */
import { dateFormat } from 'lib/utils'

/* Components */
import { FilterChipWrapper, FilterButton } from './filterChip.styles'
import { ActiveFilter, DateFilterValue } from '../Devices'

interface Props extends ActiveFilter {
  isResetButton?: boolean
  handleResetFilter: (colName: string) => void
}

const FilterChip: FC<Props> = ({ filterName = '', filterValue = [''], isResetButton, handleResetFilter }) => (
  <FilterChipWrapper className={`filter-chip-wrapper ${isResetButton ? 'reset-wrapper' : ''}`}>
    {!isResetButton && <Typography>{filterName}</Typography>}
    {isResetButton ? (
      <FilterButton className="filter-chip-reset-button" onClick={() => handleResetFilter('all')}>
        Reset all
      </FilterButton>
    ) : (
      <FilterButton className="filter-chip-button">
        <>
          {filterName === 'last seen' || filterName === 'first seen' ? (
            <>
              {(filterValue as DateFilterValue).dateTo
                ? `${dayjs((filterValue as DateFilterValue).dateFrom).format(dateFormat.historicalDay)} - ${dayjs(
                    (filterValue as DateFilterValue).dateTo
                  ).format(dateFormat.historicalDay)}`
                : (filterValue as DateFilterValue).type === 'before'
                ? `Before: ${dayjs((filterValue as DateFilterValue).dateFrom).format(dateFormat.historicalDay)}`
                : `After: ${dayjs((filterValue as DateFilterValue).dateFrom).format(dateFormat.historicalDay)}`}
            </>
          ) : (
            <>
              {(filterValue as any).length === 1
                ? (filterValue as any)[0]
                : `${(filterValue as any)[0]} (+${(filterValue as any).length - 1})`}
            </>
          )}
          <IconButton onClick={() => handleResetFilter(filterName)}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.5 13.5L13.5 4.5M4.5 4.5L13.5 13.5"
                stroke={theme.colors.textPrimary}
                stroke-width="1.12"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </IconButton>
        </>
      </FilterButton>
    )}
  </FilterChipWrapper>
)

export default FilterChip
