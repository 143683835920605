import { ChangeEvent, FC, useCallback, useMemo, useRef, useState } from 'react'
import { Box, ClickAwayListener, Typography } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GridReadyEvent, ISetFilter, ModuleRegistry, ValidationModule } from 'ag-grid-community'
import {
  AllEnterpriseModule,
  ColumnMenuModule,
  ColumnsToolPanelModule,
  ContextMenuModule,
  DateFilterModule,
  FilterChangedEvent,
  LicenseManager,
  ServerSideRowModelModule,
  SetFilterModule,
  TextFilterModule
} from 'ag-grid-enterprise'
import dayjs from 'dayjs'

/* Utils */
import axios from 'lib/axios'
import { DEVICES_COLUMN_NAME, DevicesDataItem, DevicesManifest } from 'models'
import { cpiSpecificDefaults, defaultVisibleColumns, getColumnName, getCpiSpecificFilters } from './utils'

/* Components */
import { DevicesTableContainer, HeaderInfoWrapper, ManagementStatusWrapper } from './devices.styles'
import FilterChip from './components/FilterChip'
import ControlButton from './components/ControlButton'
import ColumnPicker from './components/ColumnPicker'
import { DTPicker } from './DTPicker'
import { dateFormat, formatCpiNameToDisplayName } from '../../../../../../lib/utils'
import { getIntegrationLogo } from '../../../../coverage-analyzer/utils'
import DataDrawer from './components/DataDrawer'

ModuleRegistry.registerModules([
  AllEnterpriseModule,
  ColumnsToolPanelModule,
  ColumnMenuModule,
  ContextMenuModule,
  ServerSideRowModelModule,
  SetFilterModule,
  DateFilterModule,
  TextFilterModule,
  ValidationModule
])
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-077987}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Onyxia_Cyber_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Onyxia}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Onyxia}_need_to_be_licensed___{Onyxia}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{25_February_2026}____[v3]_[01]_MTc3MTk3NzYwMDAwMA==96bb6539a7b3dc7650ef4ae6e514c8e4'
)

function areEqual(a: null | string[], b: null | string[]) {
  if (a == null && b == null) {
    return true
  }
  if (a != null || b != null) {
    return false
  }
  if (!a || !b) return false
  return (
    (a as any).length === (b as any).length &&
    (a as any).every(function (v: any, i: any) {
      if (!b) return false
      return b[i] === v
    })
  )
}

let selectedAssetTypes: string[] | null = null

export interface DateFilterValue {
  dateFrom: string | null
  dateTo: string | null
  filterType: string
  type: string
}

export interface ActiveFilter {
  filterName: string
  filterValue: string[] | DateFilterValue
}

interface Props {
  devicesManifest: DevicesManifest
  thisCpiName: string
  fetchQuery: ({ page, payload }: { page?: number; payload?: any }) => Promise<any>
  customerName: string
}

const Devices: FC<Props> = ({ devicesManifest, thisCpiName, fetchQuery, customerName }) => {
  const ref = useRef<AgGridReact>(null)
  const [popperAnchorEl, setPopperAnchorEl] = useState<HTMLElement | null>(null)
  const [columnsPopperOpen, setColumnsPopperOpen] = useState(false)
  const cpiSpecificDefault: string | undefined = (cpiSpecificDefaults as any)[thisCpiName]
  const [columns, setColumns] = useState(
    devicesManifest.spec.columns.map((c) =>
      defaultVisibleColumns.includes(c.name)
        ? { ...c, active: true }
        : c.name === cpiSpecificDefault
        ? { ...c, active: true }
        : c
    )
  )
  const [colDefs, setColDefs] = useState<any[]>(
    columns
      .map((column) => {
        if (defaultVisibleColumns.includes(column.name) || column.name === cpiSpecificDefault) {
          if (column.name === DEVICES_COLUMN_NAME.LAST_SEEN || column.name === DEVICES_COLUMN_NAME.FIRST_SEEN) {
            return {
              field: column.name,
              headerName: getColumnName(column.name),
              headerHeight: 50,
              flex: 2,
              filter: 'agDateColumnFilter',
              sortable: false,
              filterParams: {
                values: async (params: any) => {
                  const resp = await getLookup(column.name)
                  params.success(resp)
                },
                cellHeight: 38,
                buttons: ['reset'],
                comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
                  const dateAsString = cellValue
                  if (dateAsString == null) return -1
                  const dateParts = dateAsString.split('/')
                  const cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))
                  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                    return 0
                  }
                  if (cellDate < filterLocalDateAtMidnight) {
                    return -1
                  }
                  if (cellDate > filterLocalDateAtMidnight) {
                    return 1
                  }
                },
                browserDatePicker: true,
                maxNumConditions: 1, // Removes the AND/OR section
                minValidYear: 2000,
                maxValidYear: 2025,
                inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
                filterOptions: ['before', 'after', 'inRange']
              },
              cellRenderer: (params: any) => {
                return <Typography>{params.data[column.name]}</Typography>
              }
            }
          }
          if (column.name === DEVICES_COLUMN_NAME.ONYXIA_PRODUCT_INSTANCE) {
            return {
              field: column.name,
              headerName: getColumnName(column.name),
              headerHeight: 50,
              floatingFiltersHeight: 50,
              sortable: false,
              flex: 2,
              filter: 'agSetColumnFilter',
              width: 50,
              filterParams: {
                values: async (params: any) => {
                  const resp = await getLookup(column.name)
                  params.success(resp)
                },
                cellHeight: 38,
                buttons: ['reset']
              },
              cellRenderer: (params: any) => {
                const instance = params.data[DEVICES_COLUMN_NAME.ONYXIA_PRODUCT_NAME]
                const logo = getIntegrationLogo(instance)

                /* TODO: Add tooltip to the icon */
                return (
                  <div
                    {...params}
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}
                  >
                    <img src={logo} alt="" width="24px" height="24px" />
                    {/* <Typography>{params.value}</Typography> */}
                  </div>
                )
              }
            }
          }
          if (column.name === DEVICES_COLUMN_NAME.IP_ADDRESSES) {
            return {
              field: column.name,
              headerName: getColumnName(column.name),
              headerHeight: 50,
              floatingFiltersHeight: 50,
              sortable: false,
              flex: 2,
              filter: 'agSetColumnFilter',
              filterParams: {
                values: async (params: any) => {
                  const resp = await getLookup(column.name)
                  params.success(resp)
                },
                cellHeight: 38,
                buttons: ['reset']
              },
              cellRenderer: (params: any) => {
                const value = params.data[DEVICES_COLUMN_NAME.IP_ADDRESSES]
                let newValue = value || ''
                console.log('value: ', value)
                if (value && value.includes(']') && value.includes(',')) {
                  const parsedArr = JSON.parse(value)
                  newValue = `${parsedArr[0]} +${parsedArr.length - 1}`
                }

                return <Typography>{newValue}</Typography>
              }
            }
          }
          return {
            field: column.name,
            headerName: getColumnName(column.name),
            headerHeight: 50,
            floatingFiltersHeight: 50,
            sortable: false,
            flex: 2,
            filter: 'agSetColumnFilter',
            filterParams: {
              values: async (params: any) => {
                const resp = await getLookup(column.name)
                params.success(resp)
              },
              cellHeight: 38,
              buttons: ['reset']
            },
            cellRenderer: (params: any) => {
              return <Typography>{params.data[column.name]}</Typography>
            }
          }
        }

        return null
      })
      .filter((c) => !!c)
  )
  const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>([])
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [activeRowItem, setActiveRowItem] = useState<DevicesDataItem | null>(null)

  const getDefaultColDefParams = (columnName: string) => ({
    field: columnName,
    headerName: getColumnName(columnName),
    headerHeight: 50,
    floatingFiltersHeight: 50,
    flex: 2,
    sortable: false,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: async (params: any) => {
        const resp = await getLookup(columnName)
        params.success(resp)
      },
      cellHeight: 38,
      buttons: ['reset']
    }
  })

  const handleChangeColumns = (event: ChangeEvent<HTMLInputElement>) => {
    if (!ref.current) return
    const newColumns = columns.map((c) => (event.target.name === c.name ? { ...c, active: event.target.checked } : c))
    setColumns(newColumns)

    const newColDefs: any[] = newColumns
      .map((column) => {
        return {
          ...getDefaultColDefParams(column.name),
          active: column.active
        }
      })
      .filter((c) => c.active)
    setColDefs(newColDefs)
    ref.current.api.setGridOption('columnDefs', newColDefs)
  }

  const getLookup = useCallback(async (columnId: string): Promise<string[]> => {
    try {
      const res = await axios.post(
        `https://d3isod3ln7miv5.cloudfront.net/api/v3/wf/models/device/columns/${columnId}/lookup`,
        {
          tenant_slug: 'danco'
        }
      )

      if (res.status === 200) {
        return res.data
      }
    } catch (err) {
      console.error(err)
    }

    return []
  }, [])

  // const getLookups = async (fields: string[]): Promise<Array<string[]>> => {
  //   try {
  //     const promises: Array<Promise<string[]>> = []
  //
  //     fields.forEach((field) => {
  //       const promise = async (): Promise<string[]> => {
  //         try {
  //           return await getLookup(field)
  //         } catch (err) {
  //           console.error(err)
  //         }
  //
  //         return []
  //       }
  //
  //       promises.push(promise())
  //     })
  //
  //     return await Promise.all(promises)
  //   } catch (err) {
  //     console.error(err)
  //   } finally {
  //     setFiltersFetched(true)
  //   }
  //
  //   return []
  // }

  // const fetchAndSetColDefs = async (fieldNames: string[]) => {
  //   try {
  //     const fieldValues = await getLookups(fieldNames)
  //     const newColDefs = colDefs.map((colDef, index) => {
  //       return {
  //         ...colDef,
  //         filterParams: {
  //           ...colDef.filterParams,
  //           values: fieldValues[index]
  //         }
  //       }
  //     })
  //     setColDefs(newColDefs)
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }

  // useEffect(() => {
  //   fetchQuery({}).catch((e) => e)
  // }, [])

  // useEffect(() => {
  //   if (data.length > 0 && colDefs.length > 0 && !filtersFetched) {
  //     fetchAndSetColDefs(colDefs.map((c) => c.field)).catch((e) => e)
  //   }
  // }, [data, colDefs, filtersFetched])

  // const rowSelection: any = useMemo(() => {
  //   return {
  //     mode: 'multiRow'
  //   }
  // }, [])

  const onBtnExport = useCallback(() => {
    if (!ref?.current) return

    const cpiNameFormatted = formatCpiNameToDisplayName(thisCpiName)
    const fileName = `Onyxia_${customerName}_${cpiNameFormatted}_${dayjs().format('YYYY_MM_DD_hh_mm_s')}.csv`
    ref.current.api.exportDataAsCsv({
      fileName
    })
  }, [ref, customerName, thisCpiName])

  const handleResetFilter = (colName: string) => {
    if (!ref?.current) return

    if (colName === 'all') {
      ref.current.api.setFilterModel(null)
      setActiveFilters([])
      return
    }

    const thisFieldName = colDefs.find((c) => c.headerName === colName)

    if (thisFieldName) {
      const fieldName = thisFieldName.field
      const savedFilterModel = ref.current.api.getFilterModel()
      const newFilterModel = { ...savedFilterModel }
      delete newFilterModel[fieldName]
      const newActiveFilters = [...activeFilters].filter((c) => c.filterName !== colName)

      setActiveFilters(newActiveFilters)
      ref.current.api.setFilterModel(newFilterModel)
    }
  }

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      /* CPI Specific Filters */
      const cpiSpecificFilters = getCpiSpecificFilters(thisCpiName)
      console.log('cpiSpecificFilters: ', cpiSpecificFilters)

      fetch('https://d3isod3ln7miv5.cloudfront.net/api/v3/wf/models/device/query', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          device: {
            // ...cpiSpecificFilters
          },
          page: 1,
          limit: 100,
          tenant_slug: 'danco'
        })
      })
        .then((resp) => resp.json())
        .then(() => {
          const dataSource = {
            getRows: async (params: any) => {
              const payload: Record<string, any> = {
                // ...cpiSpecificFilters
              }

              console.log('params.request: ', params.request)
              if (params.request.filterModel) {
                for (const [key, value] of Object.entries(params.request.filterModel)) {
                  if ((value as any).values && (value as any).values.length > 0) {
                    payload[key] = {
                      any_: (value as any).values
                    }
                  }
                  if ((value as any).dateFrom) {
                    const valueType = (value as any).type
                    const thisType =
                      valueType === 'inRange'
                        ? 'between_'
                        : valueType === 'before'
                        ? 'lte_'
                        : valueType === 'after'
                        ? 'gte_'
                        : valueType
                    if (thisType === 'between_') {
                      payload[key] = {
                        between_: [
                          new Date((value as any).dateFrom).toISOString().replace('T', ' ').replace('Z', ''),
                          new Date((value as any).dateTo).toISOString().replace('T', ' ').replace('Z', '')
                        ]
                      }
                    } else {
                      payload[key] = {
                        [thisType]: new Date((value as any).dateFrom).toISOString().replace('T', ' ').replace('Z', '')
                      }
                    }
                  }
                }
              }

              if (params.request.sortModel?.length > 0) {
                // const { colId, sort } = params.request.sortModel[0]
              }

              console.log('payload: ', payload)
              const page = params.request.endRow / 100
              const res = await fetchQuery({
                page,
                payload
              })
              const data = res.data.data || []
              console.log('data: ', data)
              const reworkedData = data.map((item: DevicesDataItem) => {
                return {
                  ...item,
                  [DEVICES_COLUMN_NAME.FIRST_SEEN]: item[DEVICES_COLUMN_NAME.FIRST_SEEN]
                    ? dayjs(item[DEVICES_COLUMN_NAME.FIRST_SEEN]).format(dateFormat.fullYearHour)
                    : '',
                  [DEVICES_COLUMN_NAME.LAST_SEEN]: item[DEVICES_COLUMN_NAME.LAST_SEEN]
                    ? dayjs(item[DEVICES_COLUMN_NAME.LAST_SEEN]).format(dateFormat.fullYearHour)
                    : ''
                }
              })

              if (res.status === 200) {
                params.success({
                  rowData: reworkedData
                })
              } else {
                params.fail()
              }
            }
          }

          params.api.setGridOption('serverSideDatasource', dataSource)
        })
    },
    [thisCpiName]
  )

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100
    }
  }, [])

  const handleFilterChanged = useCallback(
    (e: FilterChangedEvent<DevicesDataItem, any>) => {
      if (!ref.current) return
      const assetTypeFilterModel = ref.current.api.getFilterModel()[DEVICES_COLUMN_NAME.ASSET_TYPE]
      const selected = assetTypeFilterModel && assetTypeFilterModel.values
      if (!areEqual(selectedAssetTypes, selected)) {
        selectedAssetTypes = selected
        ref.current.api.getColumnFilterInstance<ISetFilter>(DEVICES_COLUMN_NAME.ASSET_TYPE).then((filter) => {
          if (filter) {
            filter.refreshFilterValues()
          }
        })
      }

      const state = e.api.getState()
      if (state.filter && state.filter.filterModel) {
        const thisFilters = []
        console.log('state.filter.filterModel: ', state.filter.filterModel)
        for (const [key, value] of Object.entries(state.filter.filterModel)) {
          console.log('colDefs------------: ', colDefs)
          const colDef = colDefs.find((c) => c.field === key)
          console.log('colDef:---------- ', colDef)
          thisFilters.push({
            filterName: colDef?.headerName || key,
            filterValue:
              colDef?.headerName === 'first seen'
                ? state.filter.filterModel.FIRST_SEEN
                : colDef?.headerName === 'last seen'
                ? state.filter.filterModel.LAST_SEEN
                : value.values
          })
        }
        console.log('thisFilters: ', thisFilters)
        if (thisFilters.length === 1 && !thisFilters[0].filterValue?.length) {
          if (thisFilters[0].filterValue.dateFrom) {
            setActiveFilters(
              thisFilters.map((filter) => ({
                filterName: filter.filterName,
                filterValue: filter.filterValue
              }))
            )
          } else {
            setActiveFilters([])
          }
        } else {
          setActiveFilters(
            thisFilters
              .map((filter) => ({
                filterName: filter.filterName,
                filterValue: filter.filterValue
              }))
              .filter((c) => c.filterValue.dateFrom || c.filterValue.length > 0)
          )
        }
      } else {
        setActiveFilters([])
      }
    },
    [colDefs]
  )

  const components = useMemo(
    () => ({
      agDateInput: DTPicker
    }),
    []
  )

  const handleRestoreDefaultColumns = () => {
    setColumns(
      devicesManifest.spec.columns.map((c) =>
        defaultVisibleColumns.includes(c.name)
          ? { ...c, active: true }
          : c.name === cpiSpecificDefault
          ? { ...c, active: true }
          : c
      )
    )
    setColDefs(
      columns
        .map((column) => {
          if (defaultVisibleColumns.includes(column.name) || column.name === cpiSpecificDefault) {
            if (column.name === DEVICES_COLUMN_NAME.LAST_SEEN || column.name === DEVICES_COLUMN_NAME.FIRST_SEEN) {
              return {
                field: column.name,
                headerName: getColumnName(column.name),
                headerHeight: 50,
                flex: 2,
                filter: 'agDateColumnFilter',
                sortable: false,
                filterParams: {
                  values: async (params: any) => {
                    const resp = await getLookup(column.name)
                    params.success(resp)
                  },
                  cellHeight: 38,
                  buttons: ['reset'],
                  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
                    const dateAsString = cellValue
                    if (dateAsString == null) return -1
                    const dateParts = dateAsString.split('/')
                    const cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))
                    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                      return 0
                    }
                    if (cellDate < filterLocalDateAtMidnight) {
                      return -1
                    }
                    if (cellDate > filterLocalDateAtMidnight) {
                      return 1
                    }
                  },
                  browserDatePicker: true,
                  maxNumConditions: 1, // Removes the AND/OR section
                  minValidYear: 2000,
                  maxValidYear: 2025,
                  inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
                  filterOptions: ['before', 'after', 'inRange']
                }
              }
            }
            return {
              field: column.name,
              headerName: getColumnName(column.name),
              headerHeight: 50,
              floatingFiltersHeight: 50,
              sortable: false,
              flex: 2,
              filter: 'agSetColumnFilter',
              filterParams: {
                values: async (params: any) => {
                  const resp = await getLookup(column.name)
                  params.success(resp)
                },
                cellHeight: 38,
                buttons: ['reset']
              }
            }
          }

          return null
        })
        .filter((c) => !!c)
    )
  }

  console.log('%c drawerOpen: ', 'color:orange;font-weight:bold;', drawerOpen)
  return (
    <>
      <DevicesTableContainer className="devices-table-container">
        <ManagementStatusWrapper className="management-status-wrapper">
          <Box className="filter-chips-container">
            {activeFilters.length > 0 &&
              activeFilters.map((filter, idx) => (
                <FilterChip key={idx} {...filter} handleResetFilter={handleResetFilter} />
              ))}
            {activeFilters.length > 0 && (
              <FilterChip isResetButton filterName="" filterValue={[]} handleResetFilter={handleResetFilter} />
            )}
          </Box>

          <ClickAwayListener
            onClickAway={() => {
              setColumnsPopperOpen(false)
              setPopperAnchorEl(null)
            }}
          >
            <Box className="controls-container">
              <ControlButton type="export" setAnchorEl={setPopperAnchorEl} handleClick={onBtnExport} />
              <ControlButton
                type="columns"
                setAnchorEl={setPopperAnchorEl}
                handleClick={() => setColumnsPopperOpen((pre) => !pre)}
              />
              <ColumnPicker
                anchorEl={popperAnchorEl}
                open={columnsPopperOpen}
                columns={columns}
                handleChangeColumns={handleChangeColumns}
                handleRestoreDefaultColumns={handleRestoreDefaultColumns}
              />
            </Box>
          </ClickAwayListener>
        </ManagementStatusWrapper>
        <HeaderInfoWrapper>
          <Typography>
            Number of rows:{' '}
            <span>
              {ref?.current?.api?.getDisplayedRowCount() === 101 ? 100 : ref?.current?.api?.getDisplayedRowCount()}
            </span>
          </Typography>
          <Typography>
            Last Update: <span>{dayjs().format('ddd, MMM DD YYYY')}</span>
          </Typography>
        </HeaderInfoWrapper>
        <AgGridReact<DevicesDataItem>
          ref={ref}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          // rowSelection={rowSelection}
          rowHeight={64}
          rowStyle={{
            'max-height': '60px'
          }}
          headerHeight={49}
          cacheBlockSize={100}
          maxBlocksInCache={10}
          rowModelType={'serverSide'}
          onGridReady={onGridReady}
          onFilterChanged={handleFilterChanged}
          components={components}
          onRowClicked={(row) => {
            setDrawerOpen(!drawerOpen)
            setActiveRowItem(row.data || null)
          }}
        />
      </DevicesTableContainer>
      <DataDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} activeRowItem={activeRowItem} />
    </>
  )
}

export default Devices
