import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'
import theme from 'theme'

export const LibraryDetailsInfoTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: `${theme.spacing.small}px`,
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: `${theme.spacing.sm}px`,
    borderRadius: theme.attributes.borderRadius.mediumSmall,
    border: `1px solid ${theme.colors.controlBorder}`,
    background: theme.colors.borderLow,
    boxShadow: theme.attributes.boxShadow.chipCircle
  },
  '.tooltip-text': {
    color: theme.colors.neutral,
    fontSize: theme.typography.fontSize.large,
    fontFamily: theme.typography.fontFamily.primary,
    lineHeight: '20px',
    letterSpacing: '0.25px'
  }
})

export const LibraryDetailsHoverTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.textPrimary
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.large,
    lineHeight: '20px',
    fontWeight: theme.typography.fontWeight.bold,
    color: theme.baseColors.primary[20],
    letterSpacing: '0.1px',
    borderRadius: theme.attributes.borderRadius.mediumSmall,
    boxShadow: theme.attributes.boxShadow.selectFieldPaper,
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3))',
    background: theme.colors.textPrimary
  }
})

export const CpiNavigationHoverTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.textPrimary
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.large,
    lineHeight: '20px',
    fontWeight: theme.typography.fontWeight.bold,
    color: theme.baseColors.primary[20],
    letterSpacing: '0.1px',
    borderRadius: theme.attributes.borderRadius.mediumSmall,
    boxShadow: theme.attributes.boxShadow.selectFieldPaper,
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3))',
    background: theme.colors.textPrimary
  }
})

export const AcaHoverTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.controlBorder
  },
  [`& .${tooltipClasses.tooltip}`]: {
    position: 'relative',
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.large,
    lineHeight: '20px',
    fontWeight: theme.typography.fontWeight.bold,
    color: theme.colors.white,
    letterSpacing: '0.1px',
    borderRadius: theme.attributes.borderRadius.mediumSmall,
    border: `1px solid ${theme.colors.controlBorder}`,
    boxShadow: theme.attributes.boxShadow.selectFieldPaper,
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3))',
    background: theme.colors.surfaceItemBackgroundLow
  }
})
