import styled from '@emotion/styled/macro'
import { Box, Popper as MuiPopper } from '@mui/material'
import theme from 'theme'

export const Wrapper = styled(Box)`
  .cpi-name-wrapper {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.half}px;
    cursor: pointer;

    .navigation-cpi-name {
      color: ${theme.colors.textPrimary};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.medium};
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.5px;
    }
  }
`

export const Popper = styled(MuiPopper)`
  width: 333px;
  border-radius: ${theme.attributes.borderRadius.tertiary};
  background: #393038;
  box-shadow: 0 4px 8px 0 ${theme.colors.primaryDark};
  z-index: 1000000000;
  margin-top: 7px !important;

  .content {
    .list-wrapper {
      height: 100%;
      max-height: 206px;
      overflow-y: auto;
      width: 100%;
      border-radius: ${theme.attributes.borderRadius.tertiary};

      .list {
        display: flex;
        flex-flow: column wrap;
        width: 100%;

        .list-item {
          width: 100%;
          height: 40px;
          padding: ${theme.spacing.sm}px ${theme.spacing.medium}px;
          background: #393038;
          text-overflow: ellipsis;

          &:hover {
            background: #564852;
            cursor: pointer;
          }

          .list-item-text {
            text-overflow: ellipsis;
            max-width: 321px;
            overflow: hidden;
            white-space: nowrap;
            flex-grow: 1;
            color: ${theme.colors.white};
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.large};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.bold};
            line-height: 20px;
          }
        }
      }
    }
  }
`
