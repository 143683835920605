import { Drawer as MuiDrawer } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Drawer = styled(MuiDrawer)`
  .MuiPaper-root {
    background: ${theme.colors.surfaceContainerBackground};
    min-width: 351px;
    width: 512px;
    border-left: 1px solid ${theme.colors.borderLow};

    ::-webkit-scrollbar {
      display: none;
    }

    .wrapper-inner {
      width: 100%;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: ${theme.spacing.xLarge}px ${theme.spacing.xLarge}px 0;

        .title {
          line-height: 42px;
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.xxxxLarge};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.bold};
          color: ${theme.colors.white};
        }
      }

      .content {
        padding: ${theme.spacing.large}px ${theme.spacing.xLarge}px ${theme.spacing.xLarge}px;
        overflow-y: auto;
        height: 100%;
        display: flex;
        flex-flow: column wrap;
        gap: ${theme.spacing.large}px;

        &::-webkit-scrollbar {
          width: 4px;
          background: transparent;
        }

        &::-webkit-scrollbar-track {
          box-shadow: none;
          background: ${theme.colors.borderLow};
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${theme.colors.textPrimary};
          border-radius: ${theme.attributes.borderRadius.quaternary};
        }

        .row-item {
          display: flex;
          flex-flow: column wrap;
          gap: ${theme.spacing.xSmall}px;

          .row-name {
            color: ${theme.baseColors.secondary[80]};
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.large};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.normal};
            line-height: 22px;
          }

          .row-data {
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing.xSmall}px;

            .data-item {
              display: flex;
              align-items: center;
              gap: ${theme.spacing.xSmall}px;

              img {
                min-width: 24px !important;
                min-height: 24px !important;
              }

              .name {
                color: ${theme.colors.white};
                font-family: ${theme.typography.fontFamily.primary};
                font-size: ${theme.typography.fontSize.large};
                font-style: normal;
                font-weight: ${theme.typography.fontWeight.bold};
                line-height: 20px;
                white-space: pre-line;
                word-break: break-all;

                &.empty-cell {
                  color: #939393;
                }
              }
            }

            .show-more-button {
              width: fit-content;
              cursor: pointer;
              color: ${theme.colors.textPrimary};
              font-family: ${theme.typography.fontFamily.primary};
              font-size: ${theme.typography.fontSize.large};
              font-style: normal;
              font-weight: ${theme.typography.fontWeight.bold};
              line-height: 22px;
            }
          }
        }
      }
    }
  }
`
