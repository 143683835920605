import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: ${theme.spacing.medium}px;
  box-sizing: border-box;
  height: 260px;

  .actions-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .delete-button {
      padding: 10.5px 17px;
    }

    .disabled-delete-button {
      padding: 10.5px 17px;
      min-width: 91px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;
      border-radius: ${theme.attributes.borderRadius.large};
      background: ${theme.baseColors.surface[50]};
      cursor: default;
      color: ${theme.baseColors.greyscale[50]};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.thin};
      line-height: normal;
      white-space: nowrap;

      svg {
        width: 24px;
        height: 24px;

        path {
          fill: ${theme.baseColors.greyscale[50]};
        }
      }
    }
  }
`

export const FrameworkSelectCardContainer = styled(Box)<{
  isSelected: string
  isloading: string
  hasSelectPermission?: string
}>`
  border-radius: ${theme.attributes.borderRadius.secondary};
  background: ${theme.colors.surfaceItemBackground};
  display: flex;
  padding: ${theme.spacing.medium}px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: ${theme.spacing.medium}px;
  max-width: 400px;
  min-width: 354px;
  width: 100%;
  box-sizing: border-box;
  height: 196px;

  &:hover {
    cursor: pointer;
    background: ${theme.colors.primaryLight};

    ${({ hasSelectPermission, isSelected }) =>
      hasSelectPermission &&
      hasSelectPermission === 'false' &&
      `
        cursor: default;
        background: ${isSelected === 'true' ? theme.colors.primaryLight : theme.colors.surfaceItemBackground};
        pointer-events: none;
    `};
  }

  ${({ isSelected }) =>
    isSelected === 'true' &&
    `
      background: ${theme.colors.primaryLight};
  `};

  ${({ isloading }) =>
    isloading === 'true' &&
    `
      &:hover {
        cursor: wait;
      }    
  `};
`

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .header-framework {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: ${theme.spacing.xSmall}px;

    .header-title {
      color: ${theme.colors.white};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xxxLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: normal;

      .header-subtitle {
        color: ${theme.colors.white};
        text-align: center;
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.medium};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: normal;
        margin-left: ${theme.spacing.xSmall}px;
      }
    }
  }
`

export const Content = styled(Box)`
  .content-text {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
  }
`
