import { Box, Breadcrumbs } from '@mui/material'
import styled from '@emotion/styled/macro'
import { getCpiCategoryColors } from '../../../../../lib/utils'
import { CategoryFramework, CPIManifestCategory } from '../../../../../models'
import { CustomColors } from '../LibraryDetails'
import theme from 'theme'

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.medium};
    text-decoration: none;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: ${theme.colors.white};

    &.category-breadcrumb {
      color: ${theme.colors.white};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.mediumSmall};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
      display: flex;
      padding: 2px ${theme.spacing.xSmall}px;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;
      border-radius: ${theme.attributes.borderRadius.secondary};
      background: #b15c0f;
      box-shadow: ${theme.attributes.boxShadow.quaternary};
      text-transform: capitalize;
    }
  }

  .MuiLink-root {
    color: #a88ca4;
  }

  .current-breadcrumb {
    color: ${theme.colors.textPrimary};
  }

  .MuiBreadcrumbs-separator {
    color: #a88ca4;
    font-size: ${theme.typography.fontSize.medium};
    font-weight: ${theme.typography.fontWeight.bold};
    letter-spacing: 0.1px;
  }
`

export const LibraryDetailsHeaderContainer = styled(Box)<{
  severity: '' | 'healthy' | 'problematic' | 'warning'
  category: CPIManifestCategory
  customColors?: CustomColors
}>`
  width: 100%;
  position: relative;
  z-index: 100;
  padding: ${theme.spacing.small}px ${theme.spacing.medium}px 0;
  background: ${theme.colors.surfaceContainerBackground};

  .breadcrumbs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 70%;

    .header-actions {
      display: flex;
      align-items: center;

      .favorite-icon {
        margin-right: 34px;

        .MuiButtonBase-root {
          &:hover,
          &:focus,
          &:active {
            background: rgba(202, 196, 208, 0.08);
          }
        }
      }
    }
  }

  .actions-wrapper {
    position: absolute;
    top: ${theme.spacing.medium}px;
    right: ${theme.spacing.medium}px;
    display: flex;
    flex-flow: row nowrap;
    gap: ${theme.spacing.md}px;

    .edit-button {
      margin-left: ${theme.spacing.medium}px;

      svg {
        width: 24px !important;
        height: 24px !important;
      }

      &:disabled {
        border: 1px solid rgba(227, 227, 227, 0.12);
        background: transparent;
        color: ${theme.colors.neutral};
        opacity: 0.37;
      }
    }

    .export-report-btn {
      margin-right: ${theme.spacing.medium}px;
    }

    .MuiIconButton-root {
      // border: 1px solid ${theme.colors.controlBorder};

      svg {
        path {
          //fill: ${theme.colors.white};
        }
      }
    }

    .export-report-btn {
      margin-right: ${theme.spacing.medium}px;

      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }

    .settings-btn {
      background: ${theme.colors.textPrimary};
      padding: ${theme.spacing.sm}px ${theme.spacing.medium}px ${theme.spacing.sm}px ${theme.spacing.small}px;
      gap: ${theme.spacing.xSmall}px;
      min-width: 121px;
      width: fit-content;
      height: 40px;
      border-radius: ${theme.attributes.borderRadius.large};
      font-family: ${theme.typography.fontFamily.primary};
      text-transform: none;
      font-weight: 550;
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      letter-spacing: 0.1px;
      color: ${theme.baseColors.primary[20]};
      margin-left: 24.5px;

      svg {
        path {
          fill: ${theme.baseColors.primary[20]};
        }
      }
    }
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.md}px;
    padding: ${theme.spacing.small}px 0 ${theme.spacing.md}px;

    &.category-title-wrapper {
      padding: ${theme.spacing.medium}px ${theme.spacing.medium}px ${theme.spacing.small}px ${theme.spacing.medium}px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .category-header-title {
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xxxxLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: 36px;
        text-transform: capitalize;
      }
    }

    .MuiButtonBase-root {
      padding-left: 0;
    }
  }

  .header-title {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.epic};
    line-height: 48px;
    color: ${theme.colors.white};
    max-width: 70%;

    .MuiButtonBase-root {
      margin-left: ${theme.spacing.medium}px;
    }
  }

  .chips-wrapper {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xSmall}px;

    .cpi-chip {
      padding: 2px ${theme.spacing.md}px;
      gap: 3px;
      background: ${theme.colors.primaryLight};
      min-width: 81px;
      width: fit-content;
      min-height: 24px;
      border: 1px solid #debdd9;
      box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
      border-radius: 18px;
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1px;
      color: ${theme.baseColors.primary[90]};
    }

    .cpi-category {
      padding: ${theme.spacing.xsm}px ${theme.spacing.small}px;
      gap: ${theme.spacing.xSmall}px;
      box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
      border-radius: ${theme.attributes.borderRadius.secondary};
      width: fit-content;
      min-height: 32px;
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1px;
      cursor: pointer;

      ${({ category, customColors }) => {
        if (customColors) {
          return `
          background: ${customColors.chip_bg_color};
          color: ${customColors.chip_color};
        `
        } else {
          const categoryId = category?.id
          const fw = category?.framework_id || ''
          const framework =
            fw === CategoryFramework.Onyxia
              ? CategoryFramework.Onyxia
              : fw === CategoryFramework.NIST
              ? CategoryFramework.NIST
              : CategoryFramework.Custom
          const colors = getCpiCategoryColors(categoryId, framework)

          return `
          background: ${colors.background};
          color: ${colors.color};
        `
        }
      }}
    }

    .cpi-framework-panel {
      .cpi-framework {
        display: flex;
        padding: 5px 15px 5px ${theme.spacing.xSmall}px;
        align-items: center;
        gap: ${theme.spacing.xSmall}px;
        border-radius: ${theme.attributes.borderRadius.secondary};
        border: 1px solid ${theme.colors.controlBorder};

        img {
          width: 18px;
          height: 18px;
        }

        .framework-title {
          color: ${theme.colors.neutral};
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.large};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.bold};
          line-height: 20px;
          letter-spacing: 0.1px;
        }
      }
    }

    .cpi-active-button {
      width: fit-content;
      height: 32px;
      box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
      border-radius: ${theme.attributes.borderRadius.secondary};
      padding: ${theme.spacing.xsm}px ${theme.spacing.small}px ${theme.spacing.xsm}px ${theme.spacing.xSmall}px;
      gap: ${theme.spacing.xSmall}px;
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1px;
      color: ${theme.colors.white};
    }

    .cpi-active {
      background: ${theme.baseColors.info[10]};
    }

    .cpi-inactive {
      background: ${theme.colors.borderLow};
    }
  }

  .header-info {
    display: flex;

    > * > * {
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.normal};
      font-size: ${theme.typography.fontSize.medium};
      line-height: 16px;
      letter-spacing: 0.4px;
      color: ${theme.baseColors.greyscale[80]};
    }

    .header-info__item {
      padding-right: ${theme.spacing.xLarge}px;
    }

    .header-info__title {
      margin-bottom: ${theme.spacing.half}px;
    }

    .health-threshold {
      font-family: ${theme.typography.fontFamily.primary};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      letter-spacing: 0.1px;
      color: ${theme.colors.neutral};
      min-width: 102px;
      height: 32px;
      background: rgba(114, 226, 141, 0.3);
      box-shadow: ${theme.attributes.boxShadow.quaternary};
      border-radius: ${theme.attributes.borderRadius.secondary};
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: ${theme.spacing.xSmall}px;
      }

      ${({ severity }) =>
        severity &&
        severity === 'problematic' &&
        `
        background: rgba(246, 103, 123, 0.3);
        min-width: 125px;
      `};

      ${({ severity }) =>
        severity &&
        severity === 'warning' &&
        `
        background: rgba(244, 181, 127, 0.4);
      `};

      ${({ severity }) =>
        severity &&
        severity === 'healthy' &&
        `
        background: rgba(114, 226, 141, 0.3);
      `};
    }

    .header-info__category {
      background: #debdd9;
      box-shadow: ${theme.attributes.boxShadow.quaternary};
      border-radius: ${theme.attributes.borderRadius.secondary};
      width: fit-content;
      padding: ${theme.spacing.xsm}px ${theme.spacing.xSmall}px;
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      font-weight: ${theme.typography.fontWeight.bold};
      text-transform: capitalize;
      color: #522217;
      letter-spacing: 0.1px;
    }
  }
`

export const LibraryHeaderBackground = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/details-bg-1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
  box-shadow: ${theme.attributes.boxShadow.quaternary};
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
  border-radius: ${theme.attributes.borderRadius.quaternary} 0 0 0;
  mix-blend-mode: luminosity;
`
