import { useCallback, Dispatch, SetStateAction, useEffect, useState } from 'react'
import axios from 'lib/axios'
import { ConfiguredCPI, DEVICES_COLUMN_NAME, DevicesDataItem, DevicesManifest } from 'models'
import { getCpiSpecificFilters } from './sections/devices/utils'

export interface Pagination {
  current_page: number
  next_page: number | null
  previous_page: number | null
}

interface Props {
  thisCpiName: string
  cpiConfiguration: ConfiguredCPI | null
  setTabValue: Dispatch<SetStateAction<number>>
}

const useDevicesData = ({
  // setTabValue,
  thisCpiName,
  cpiConfiguration
}: Props) => {
  const [devicesManifest, setDevicesManifest] = useState<DevicesManifest | null>(null)
  const [devicesCpiSupported, setDevicesCpiSupported] = useState(false)
  const [devicesIntegrationSupported, setDevicesIntegrationSupported] = useState(false)
  const [data, setData] = useState<DevicesDataItem[]>([])
  const [pagination, setPagination] = useState<Pagination>({
    current_page: 1,
    next_page: null,
    previous_page: null
  })

  const fetchManifest = async () => {
    try {
      const res = await axios.post('https://d3isod3ln7miv5.cloudfront.net/api/v3/wf/manifests/models/filter?manifest', {
        manifest: {
          operator: 'and_',
          slug: {
            any_: ['device']
          }
        },
        sort: 'CREATED_DESC'
      })

      if (res.status === 200) {
        const data = res.data[0] || null
        setDevicesManifest(data)
        if (data) {
          const cpiSupported = data.supported_metrics.includes(thisCpiName)
          if (!cpiSupported) {
            // setTabValue(0)
          }
          setDevicesCpiSupported(cpiSupported)
          if (cpiConfiguration) {
            const thisCpiIntegrations = cpiConfiguration.integrations
            if (thisCpiIntegrations.some((c) => data.supported_products.includes(c.name))) {
              setDevicesIntegrationSupported(true)
            }
          }
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  const fetchQuery = useCallback(
    async ({ page = 1, payload }: { page?: number; payload?: any }) => {
      if (!devicesManifest) return
      const columns = devicesManifest.spec.columns

      const deviceProps: any = {}
      columns.forEach((column) => {
        const { type } = column
        if (column.name === DEVICES_COLUMN_NAME.FIRST_SEEN || column.name === DEVICES_COLUMN_NAME.LAST_SEEN) {
          deviceProps[column.name] = {
            gte_: new Date().toISOString().replace('T', ' ').replace('Z', '')
          }
        } else if (type === 'boolean') {
          deviceProps[column.name] = {
            eq_: true
          }
        } else {
          deviceProps[column.name] = {
            any_: ['string']
          }
        }
      })

      const cpiSpecificFilters = getCpiSpecificFilters(thisCpiName)
      console.log('cpiSpecificFilters: ', cpiSpecificFilters)

      try {
        const res = await axios.post('https://d3isod3ln7miv5.cloudfront.net/api/v3/wf/models/device/query', {
          device: {
            // ...cpiSpecificFilters,
            ...payload
          },
          page,
          limit: 100,
          tenant_slug: 'danco'
        })

        if (res.status === 200) {
          const { data } = res

          setPagination(data.pagination)
          setData(data.data)

          return res
        }
      } catch (err) {
        console.error(err)
      }
    },
    [devicesManifest]
  )

  useEffect(() => {
    if (thisCpiName && cpiConfiguration) {
      fetchManifest().catch((e) => e)
    }
  }, [thisCpiName, cpiConfiguration])

  return {
    devicesData: data,
    devicesManifest,
    devicesCpiSupported,
    devicesIntegrationSupported,
    fetchQuery,
    devicesPagination: pagination
  }
}

export default useDevicesData
